/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiInterfaceV2.1.js*
 *
 * ### Content
 *   * Abstract definition of the ShapeDiver 3D Viewer API V2.1
 *
 * @module ApiInterfaceV2
 * @author ShapeDiver <contact@shapediver.com>
 */

const ARAPIINTERFACE = require('../../ar/ArApiInterface');


////////////
////////////
//
// Shared Types
//
////////////
////////////

/**
 * Every API function returns an object of this format
 *
 * @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#APIResponse
 * @property {Error} [err] - Error object if applicable, note that both err and data may be set.
 * @property {*} [err.data] - Optional further data explaining the error.
 * @property {*} [data] - The data resulting from the API call if applicable.
 * @property {*} [payload] - Payload which optionally may be passed to asynchronous functions.
*/

/**
* 3D point or vector defined by an array of three numbers
* @typedef {Number[]} module:ApiInterfaceV2~ApiInterfaceV2#Point3dArray
*/

/**
* 3D point or vector defined by an object of three coordinates x,y,z
* @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#Point3d
* @property {Number} [x] - x coordinate of point or vector
* @property {Number} [y] - y coordinate of point or vector
* @property {Number} [z] - z coordinate of point or vector
*/

/**
* Color including alpha value defined by an array of 4 numbers between 0 and 255 [red, green, blue, alpha].
* Further supported are all formats accepted by {@link https://www.npmjs.com/package/tinycolor2 tinycolor}.
* @typedef {Number[]|String|Number} module:ApiInterfaceV2~ApiInterfaceV2#Color
*/

/**
* Event listener token - when adding an event listener, remember this token if you wish to remove the event listener again later
* @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken
*/

/**
* Scene paths are composed from the following parts in dot-separated format:
*   * Runtime id of the plugin owning the object. In case of an object added via an API object, the runtime id of the API object.
*   * Id of the SceneAsset which the object belongs to.
*   * A part corresponding to SceneAssetItems in the content array of the SceneAsset.
*   * A part corresponding to the transformations of the SceneAssetItem.
*   * Further path of the object and subobjects, depending on its format and structure.
* An example scene path: CommPlugin_1.Asset_Id_1.Content_Item_1.Transformation_1.further.path.to.object
*
* @typedef {String} module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType
*/

/**
* Event type definition
* @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#Event
* @property {String} type - type of event as specified when registering an event listener
* @property {Object} api - Reference to the instance of the API object which was used to register the event listener
* @property {Object} [token] - optional message token created by internal processes of the ShapeDiver 3D viewer
* @property {String} [token.id] - id of the message token, the same id is used for all messages belonging to the same process
* @property {Number} [token.serial] - serial number of the message token, increases for every message belonging to the same process
* @property {*} [token.payload] - payload which optionally may be passed to asynchronous functions, e.g. a token used to identify progress or status messages of asynchronous processes
* @property {Number} [progress] - In case this is a progress event, a number describing the progress in interval [0,1]
* @property {String} [message] - An optional status message which could be displayed to the user, e.g. if computation fails etc
* @property {Number} [messageLevel] - Optional level of message to show to the user, one of 0 (error), 1 (warn), 2 (info), 3 (debug)
* @property {String} [messageScope] - An optional description of the scope which the status message originates from
* @property {String} [containerId] - Optional id of container this message relates to
* @property {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType} [scenePath] - In case of an event related to a geometric object in the scene, this specifies the path in the scene graph
* @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [dragPosStart] - In case this is a dragstart event, the starting position of a drag operation
* @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [dragPosAbs] - In case this is a dragmove or dragend event, the current absolute position of the drag operation
* @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [dragPosRel] - In case this is a dragmove or dragend event, the current relative position of the drag operation
* @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [hoverPos] - In case this is a hover event, the ray intersection point
* @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [selectPos] - In case this is a selection event, the ray intersection point
* @property {Number} [clientX] - In case of an interaction event, the horizontal coordinate (according to the current window) of the mouse pointer when a mouse event was triggered
* @property {Number} [clientY] - In case of an interaction event, the vertical coordinate (according to the current window) of the mouse pointer when a mouse event was triggered
* @property {module:ApiInterfaceV2~AnchorEventData[]} [anchors] -  In case of an anchor event, an array of {@link module:ApiInterfaceV2~ApiInterfaceV2#AnchorEventData AnchorEventData} objects
* @property {module:ApiExportInterface~ApiExportInterface#ExportResult|*} [export] - data related to an export: status message, or availability message (final success)
* @property {ParameterDefinition|*} [parameter] - data related to a parameter, depending on event type: a parameter definition, or an object mapping plugin id to updated parameter ids to updated parameter value
* @property {String[]} [assets] - object describing an update to scene assets (persistent attributes applied), for event type SUBSCENE_PUBLISHED
* @property {Object} [settings] - data related to a settings update
* @property {String} [settings.key] - key of the setting which changed
* @property {*} [settings.valueNew] - new value of the setting
* @property {*} [settings.valueOld] - old value of the setting
* @property {module:CameraApi~CameraApi#CameraDefinition} [cameraDefinition] - In case of a camera event, the current camera definition
* @property {Number} [duration] - In case of a camera event, the current camera movement duration
* @property {Number} [framerate] - In case of a framerate event, the framerate of the last second (if it was rendering) 
*/

/**
 * Event listener callback
 * @callback module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback
 * @param {module:ApiInterfaceV2~ApiInterfaceV2#Event} event - event object depending on event type
 */


/**
 * @callback module:ApiInterfaceV2~ApiInterfaceV2#AnchorUpdateCallback
 * @param {module:ApiInterfaceV2~ApiInterfaceV2#AnchorEventData} event
 */

/**
  * Data type definition for events of type {@link module:ApiSceneInterface~ApiSceneInterface#EVENTTYPE ANCHOR_ADD} or {@link module:ApiSceneInterface~ApiSceneInterface#EVENTTYPE ANCHOR_UPDATE} (not emitted by viewer via addEventListener, use updateFunction instead) or {@link module:ApiSceneInterface~ApiSceneInterface#EVENTTYPE ANCHOR_REMOVE}
  * @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#AnchorEventData
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} location - The 3D point defining the location of the anchor
  * @property {Number} [containerX] - horizontal coordinate (relative to the top-left corner of the viewer container) of the 3D position
  * @property {Number} [containerY] - vertical coordinate (relative to the top-left corner of the viewer container) of the 3D position
  * @property {Number} [clientX] - horizontal coordinate (relative to the top-left corner of the viewport) of the 3D position
  * @property {Number} [clientY] - vertical coordinate (relative to the top-left corner of the viewport) of the 3D position
  * @property {Number} [pageX] - horizontal coordinate (relative to the top-left corner of the document) of the 3D position
  * @property {Number} [pageY] - vertical coordinate (relative to the top-left corner of the document) of the 3D position
  * @property {Number} [containerWidth] - the width of the viewer container
  * @property {Number} [containerHeight] - the height of the viewer container
  * @property {String} [viewport] - runtime id of the viewport reporting the anchor position
  * @property {Boolean} [hidden] - true if the anchor is hidden behind other objects, false if not
  * @property {Boolean} [overflow] - true if the projected 3D anchor position is outside the viewport container
  * @property {Number} [distance] - distance between the camera and the anchor location (perspective camera only)
  * @property {Number} [viewingAngle] - the angle between the camera direction and direction from the camera origin to the anchor
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType} [scenePath] - this specifies the path of the anchor object in the scene graph
  * @property {String} [format] - optional format description of the anchor object, this property is passed on from the anchor definition (e.g. {@link module:ApiSceneInterface~ApiSceneInterface#AnchorDataFormatText text} or {@link module:ApiSceneInterface~ApiSceneInterface#AnchorDataFormatImage image})
  * @property {Object} [data] - optional data of the anchor object, this property is passed on from the anchor definition (e.g. {@link module:ApiSceneInterface~ApiSceneInterface#AnchorDataFormatText AnchorDataFormatText} or {@link module:ApiSceneInterface~ApiSceneInterface#AnchorDataFormatImage AnchorDataFormatImage})
  * @property {Function} [updateFunction] - only available in events of type {@link module:ApiSceneInterface~ApiSceneInterface#EVENTTYPE ANCHOR_ADD}; can be used to set a callback to receive {@link module:ApiInterfaceV2~ApiInterfaceV2#AnchorEventData AnchorEventData} events whenever the projected 3D coordinates of the anchor location change.
  *                                         The callback that must be provided has to be of type {@link module:ApiInterfaceV2~ApiInterfaceV2#AnchorUpdateCallback}.
  */

/**
* Definition of a transition function
* @typedef {Object} module:ApiInterfaceV2~ApiInterfaceV2#TransitionParameters
* @property {Number} [duration] - duration of the transition in milliseconds
* @property {String|Function} [easing='Quartic.InOut'] - In case a string S is provided, the corresponding easing function TWEEN.Easing[S] will be used if it exists. The easing function may also be passed directly, e.g. one of the many provided by {@link https://github.com/tweenjs/tween.js/blob/master/docs/user_guide.md Tween}, see also {@link https://5013.es/toys/tween.audio/ TweenExplained}, or a manually defined one.
* @property {String} [interpolation='CatmullRom'] - In case a string S is provided, the corresponding interpolation function TWEEN.Interpolation[S] will be used if it exists. Tween supports Linear, Bezier, and CatmullRom.
* @property {String} [coordinates='cylindrical'] - Defines coordinate system to use for animated camera paths. One of 'spherical' or 'cylindrical'.
*/

const ApiParameterInterface = require('./interface/ApiParameterInterfaceV2.1');
const ApiExportInterface = require('./interface/ApiExportInterfaceV2.1');
const ApiPluginInterface = require('./interface/ApiPluginInterfaceV2.1');
const ApiStateInterface = require('./interface/ApiStateInterfaceV2.1');
const ApiViewportsInterface = require('./interface/ApiViewportsInterfaceV2.1');
const ApiSceneInterface = require('./interface/ApiSceneInterfaceV2.1');


////////////
////////////
//
// Global API
//
////////////
////////////

/**
  * Viewer general event type, see {@link module:ApiInterfaceV2~ApiInterfaceV2#EVENTTYPE} for values
  * @typedef {String} module:ApiInterfaceV2~ApiInterfaceV2#ApiEventType
  */

/**
  * ### ShapeDiver - Viewer API
  *
  * Definition of the API v2, the default API to use when working with the ShapeDiver 3D Viewer v2.
  * This interface describes the object returned when requesting an API v2 object from an instance of the ShapeDiver 3D Viewer v2, e.g. like so:
  *
  * ```
  * // container for the viewport, typically this is a <div>
  * var _container = document.getElementById('sdv-container-viewport');
  *
  * // viewer settings (minimum example)
  * var _viewerSettings = {
  *   // container to use
  *   container: _container
  * };
  *
  * // create the viewer, get back an API v2 object
  * var api = new SDVApp.ParametricViewer(_viewerSettings);
  *
  * // further API v2 objects can be created if needed, each one having its own runtime id:
  * var api_other = api.getApiV2();
  * console.log('Runtime id of default API object', api.getRuntimeId());
  * console.log('Runtime id of second API object', api_other.getRuntimeId());
  * ```
  *
  * The API v2 provides access to the
  *  * {@link module:ApiSceneInterface~ApiSceneInterface 3D scene} including geometry, camera, lights, and interactions,
  *  * {@link module:ApiPluginInterface~ApiPluginInterface plugins}, e.g. for adding further instances of the ShapeDiver Communication Plugin,
  *  * {@link module:ApiViewportsInterface~ApiViewportsInterface viewports}, e.g. for adding and removing viewports,
  *  * {@link module:ApiParameterInterface~ApiParameterInterface parameters} which may have been registered by plugins,
  *  * {@link module:ArApiInterface~ArApiInterface AR scene settings} which may be available depending on device and environment,
  *  * {@link module:ApiExportInterface~ApiExportInterface exports} which may have been registered by plugins,
  *  * current {@link module:ApiStateInterface~ApiStateInterface state} of the viewer, and
  *  * {@link module:ApiInterfaceV2~ApiInterfaceV2#getSettings settings}
  *
  * @interface ApiInterfaceV2
  */
var ApiInterfaceV2 = function() {

  /**
   * Enum for supported event types.
   * @readonly
   * @enum {module:ApiInterfaceV2~ApiInterfaceV2#ApiEventType}
   */
  this.EVENTTYPE = {
    /** the value of a setting has been changed */
    SETTINGS_UPDATE : 'settings.update',
    /** a settings registration request (typically triggered by initialisation of the first CommPlugin) has finished processing and settings have been updated */
    SETTINGS_REGISTERED : 'settings.registered',
  };

  /**
   * State interface - use this to observe and manage the general state of the viewer
   * @type {module:ApiStateInterface~ApiStateInterface}
   */
  this.state = new ApiStateInterface();

  /**
   * Parameter interface - use this to find out which parameters have been registered by plugins, and to update their values, which in turn updates the scene
   * @type {module:ApiParameterInterface~ApiParameterInterface}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#plugins}
   */
  this.parameters = new ApiParameterInterface();

  /**
   * Export interface - use this to find out which exports have been registered by plugins, and to request exports
   * @type {module:ApiExportInterface~ApiExportInterface}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#plugins}
   */
  this.exports = new ApiExportInterface();

  /**
   * Plugin interface - use this to register and deregister instances of plugins, ask for their status, etc
   * @type {module:ApiPluginInterface~ApiPluginInterface}
   */
  this.plugins = new ApiPluginInterface();

  /**
   * Viewports interface - use to manage the viewports
   * @type {module:ApiViewportsInterface~ApiViewportsInterface}
   */
  this.viewports = new ApiViewportsInterface();

  /**
   * Scene interface - use this to manage the scene (geometry, interactions)
   * @type {module:ApiSceneInterface~ApiSceneInterface}
   */
  this.scene = new ApiSceneInterface();

  /**
   * AR interface - use this to switch between orbit and AR mode, and control details of AR mode
   * @type {module:ArApiInterface~ArApiInterface}
   */
  this.ar = new ARAPIINTERFACE();

  /**
   * Add a callback to be invoked when a certain event occurs
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ApiEventType} type - Type of event to subscribe to
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback} callback - Function to be called when the event fires
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an {@link module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken EventListenerToken} object. The event listener token can be used to remove the event listener by calling {@link module:ApiInterfaceV2~ApiInterfaceV2#removeEventListener removeEventListener}.
   */
  this.addEventListener = function() {};

  /**
   * Remove an event listener
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken} token - event listener token for event listener to be removed
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the event listener could be removed.
   */
  this.removeEventListener = function() {};

  /**
   * Provides an object describing all available settings
   *
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSetting}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#updateSettingAsync}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#updateSettingsAsync}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSettings}
   *
   * @return {Object} object describing all available settings, their type, and constraints
   */
  this.getSettingDefinitions = function() {};

  /**
   * Provides a copy of all or multiple settings
   *
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSetting}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#updateSettingAsync}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#updateSettingsAsync}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSettingDefinitions}

   * @param {String[]} [keys] - optional array of keys, keys which don't exist are ignored, if omitted all settings will be returned
   * @return {Object} object containing a copy of the settings
   */
  this.getSettings = function() {};

  /**
   * Provides a copy of an individual setting
   *
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSettings}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#updateSettingAsync}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#updateSettingsAsync}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSettingDefinitions}
   *
   * @param {String} key - name of setting (e.g. 'scene.render.shadows')
   * @return {*} a copy of the setting, undefined if not found
   */
  this.getSetting = function() {};

  /**
   * Update an individual setting if allowed, by providing the key of the setting and its new value.
   * 
   * Example: `api.updateSettingAsync('blurSceneWhenBusy', false);`
   * 
   * #### Global
   * 
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | blurSceneWhenBusy | Boolean | Blur or don't blur the scene while a process is busy |
   * | ignoreSuperseded | Boolean | Ignore intermediate solutions which at the time of their arrival have already been superseded by another customization request |
   * | loggingLevel | Number | Level of log messages shown on the console, allowed values: -1 (none), 0 (error), 1 (warn), 2 (info), 3 (debug) |
   * | messageLoggingLevel | Number | Log level to be used for logging internal messages, allowed values: -1 (none), 0 (error), 1 (warn), 2 (info), 3 (debug) |
   * | showMessages | Boolean | Show or don't show user messages in the viewport(s) |
   * | viewerRuntimeId | String | The runtime id of this viewer |
   *
   * 
   * #### Default Material
   * accessible with 'defaultMaterial', e.g. `api.updateSettingAsync('defaultMaterial.metalness', 0.5);`
   * 
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | color | {@link module:ApiInterfaceV2~ApiInterfaceV2#Color Color} | Color of the default material |
   * | bumpAmplitude | Number | Bump amplitude of the default material |
   * | metalness | Number | Metalness of the default material |
   * | roughness | Number | Roughness of the default material |
   * 
   * 
   * #### AR
   * accessible with 'ar', e.g. `api.updateSettingAsync('ar.enableCameraSync', true);`
   * 
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | enableCameraSync | Boolean | Enable / disable synchronisation of the camera with AR tracking information. Enabling this will disable the orbit controls. |
   * | enableLightingEstimation | Boolean | Enable / disable automatic lighting estimation. Enabling this stores the current state of the lights which will get restored once automatic lighting estimation gets disabled again. |
   * | enableTouchControls | Boolean | Enable / disable touch controls for placement of objects in the AR scene while AR camera synchronisation is enabled. |
   * | enableTouchControlRotation | Boolean | Enable / disable rotation of objects in the AR scene by means of touch controls. Typically this should be enabled for objects to be placed horizontally. |
   * | enableAutomaticPlacement | Boolean | Enable / disable initial automatic placement of objects in the AR scene as soon as plane anchors get detected. Automatic placement stops once the user starts to interact. |
   * | defaultHitTestType | String | Default type of feature to use for hit tests, used by touch controls. |
   *
   *
   * #### Scene
   * accessible with 'scene', e.g. `api.updateSettingAsync('scene.gridVisibility', false);`
   * 
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | duration | Number | Set fade in / fade out duration |
   * | fullscreen | Boolean | Enable / disable fullscreen mode |
   * | gridVisibility | Boolean | Show / hide the grid |
   * | groundPlaneVisibility | Boolean | Show / hide the ground plane |
   * | groundPlaneReflectionVisibility | Boolean | Enable / disable the reflectivity of the groundplane |
   * | groundPlaneReflectionThreshold | Number | Allows to control the reflectivity of the groundplane |
   * | show | Boolean | Show / hide the scene |
   *
   *
   * #### Camera
   * accessible with 'scene.camera', e.g. `api.updateSettingAsync('scene.camera.revertAtMouseUp', false);`
   * 
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | autoAdjust | Boolean | Enable / disable that the camera adjusts to geometry updates |
   * | cameraMovementDuration | Number | Default duration of camera movements |
   * | enableCameraControls | Boolean | Enable / disable camera controls |
   * | revertAtMouseUp | Boolean | Enable / disable if the camera should be reset to its default position and target on mouse up |
   * | revertAtMouseUpDuration | Number | The duration of the transition of the revertAtMouseUp |
   * | zoomExtentsFactor | Number | Factor to apply to the bounding box before zooming to extents |
   * | cameraTypes.active | {@link module:CameraApi~CameraApi#TYPE CameraType} | Set camera type |
   * 
   * 
   * #### Perspective Camera
   * accessible with 'scene.camera.cameraTypes.perspective', e.g. `api.updateSettingAsync('scene.camera.cameraTypes.perspective.fov', 15);`
   * 
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | default.position | {@link module:ApiInterfaceV2~Point3d Point3d}, {@link module:ApiInterfaceV2~Point3d Point3dArray} | Default position for the perspective camera |
   * | default.target | {@link module:ApiInterfaceV2~Point3d Point3d}, {@link module:ApiInterfaceV2~Point3d Point3dArray} | Default target for the perspective camera |
   * | fov | Number | Camera frustum vertical field of view angle, unit degree, interval [0,180] |
   * | controls | {@link module:CameraApi~CameraApi#CONTROLTYPE ControlType}  | Set the camera control type |
   * 
   * 
   * #### Orthographic Camera
   * accessible with 'scene.camera.cameraTypes.orthographic', e.g. `api.updateSettingAsync('scene.camera.cameraTypes.orthographic.default.target', {x: 0, y: 10, z: 100});`
   * 
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | default.position | {@link module:ApiInterfaceV2~Point3d Point3d}, {@link module:ApiInterfaceV2~Point3d Point3dArray} | Default position for the orthographic camera |
   * | default.target | {@link module:ApiInterfaceV2~Point3d Point3d}, {@link module:ApiInterfaceV2~Point3d Point3dArray} | Default target for the orthographic camera |
   * 
   * 
   * #### Orbit Controls
   * accessible with 'scene.camera.controls.orbit', e.g. `api.updateSettingAsync('scene.camera.controls.orbit.enablePan', false);`
   * 
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | autoRotationSpeed | Number | Speed of autoration, can be negative, also refer to enableAutoRotation |
   * | damping | Number | How much to damp camera movements by the user |
   * | enableAutoRotation | Boolean | Enable / disable automatic rotation of the camera, also refer to autoRotationSpeed |
   * | enableKeyPan | Boolean | Enable / disable panning using the keyboard, also refer to enablePan |
   * | enablePan | Boolean | Enable / disable panning in general, also refer to enableKeyPan |
   * | enableRotation | Boolean | Enable / disable camera rotation |
   * | enableZoom | Boolean | Enable / disable zooming |
   * | keyPanSpeed | Number | Speed of panning when using the keyboard |
   * | movementSmoothness | Number | How much the current movement is affected by the previous one |
   * | rotationSpeed | Number | Speed of camera rotation |
   * | panSpeed | Number | Speed of panning |
   * | zoomSpeed | Number | Speed of zooming |
   * 
   * 
   * #### Orbit Controls Restriction
   * accessible with 'scene.camera.controls.orbit.restrictions', e.g. `api.updateSettingAsync('scene.camera.controls.orbit.restrictions.position.sphere.radius', 1000);`
   * 
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | position.cube.min | {@link module:ApiInterfaceV2~Point3d Point3d}, {@link module:ApiInterfaceV2~Point3d Point3dArray} | Restriction of the camera position inside a cube, minimum corner of the cube |
   * | position.cube.max | {@link module:ApiInterfaceV2~Point3d Point3d}, {@link module:ApiInterfaceV2~Point3d Point3dArray} | Restriction of the camera position inside a cube, maximum corner of the cube |
   * | position.sphere.center | {@link module:ApiInterfaceV2~Point3d Point3d}, {@link module:ApiInterfaceV2~Point3d Point3dArray} | Restriction of the camera position inside a sphere, center of the sphere |
   * | position.sphere.radius | Number | Restriction of the camera position inside a sphere, radius of the sphere |
   * | target.cube.min | {@link module:ApiInterfaceV2~Point3d Point3d}, {@link module:ApiInterfaceV2~Point3d Point3dArray} | Restriction of the camera target inside a cube, minimum corner of the cube |
   * | target.cube.max | {@link module:ApiInterfaceV2~Point3d Point3d}, {@link module:ApiInterfaceV2~Point3d Point3dArray} | Restriction of the camera target inside a cube, maximum corner of the cube |
   * | target.sphere.center | {@link module:ApiInterfaceV2~Point3d Point3d}, {@link module:ApiInterfaceV2~Point3d Point3dArray} | Restriction of the camera target inside a sphere, center of the sphere |
   * | target.sphere.radius | Number | Restriction of the camera target inside a sphere, radius of the sphere |
   * | rotation.minPolarAngle | Number | Minimum polar angle of the camera position with respect to the camera target, unit degree, interval [0,180] |
   * | rotation.maxPolarAngle | Number | Maximum polar angle of the camera position with respect to the camera target, unit degree, interval [0,180] |
   * | rotation.minAzimuthAngle | Number | Minimum azimuth angle of the camera position with respect to the camera target, unit degree, interval [-180,180] |
   * | rotation.maxAzimuthAngle | Number | Maximum azimuth angle of the camera position with respect to the camera target, unit degree, interval [-180,180] |
   * | zoom.minDistance | Number | Minimum distance between camera position and target |
   * | zoom.maxDistance | Number | Maximum distance between camera position and target |
   *
   * 
   * 
   * #### Orthographic Controls
   * accessible with 'scene.camera.controls.orthographic', e.g. `api.updateSettingAsync('scene.camera.controls.orthographic.enableZoom', false);`
   * 
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | damping | Number | How much to damp camera movements by the user |
   * | enableKeyPan | Boolean | Enable / disable panning using the keyboard, also refer to enablePan |
   * | enablePan | Boolean | Enable / disable panning in general, also refer to enableKeyPan |
   * | enableZoom | Boolean | Enable / disable zooming |
   * | keyPanSpeed | Number | Speed of panning when using the keyboard |
   * | movementSmoothness | Number | How much the current movement is affected by the previous one |
   * | panSpeed | Number | Speed of panning |
   * | zoomSpeed | Number | Speed of zooming |
   *    
   * 
   *
   * #### Light
   * accessible with 'scene.lights', e.g. `api.updateSettingAsync('scene.lights.helper', true);`
   * 
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | helper | Boolean | Toggles the light helpers |
   * | lightScene | String | Sets the current light scene to the light scene with this name |
   * 
   * 
   * #### Material
   * accessible with 'scene.material', e.g. `api.updateSettingAsync('scene.material.environmentMapAsBackground', true);`
   * 
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | environmentMapAsBackground | Boolean | Show / hide the environment map in the background |
   * | environmentMap | String, String[] | Name of the environment map to use, or an array of 6 image URLs making up the cube mapped environment map |
   * | environmentMapResolution | String | Image resolution to be used for the named environment maps (available resolutions: '256', '512', '1024', '2048') |
   *
   * 
   * #### Render settings
   * accessible with 'scene.render', e.g. `api.updateSettingAsync('scene.render.shadows', true);`
   * 
   * | Name of setting        | Type           | Description  |
   * | :-------------: |:-------------:| -----|
   * | ambientOcclusion | Boolean | Enable / disable ambient occlusion for rendering |
   * | beautyRenderDelay | Number | Amount of which the beauty rendering is delayed |
   * | clearAlpha | Number | Set background alpha value |
   * | clearColor | {@link module:ApiInterfaceV2~Color Color} | Set background color |
   * | pointSize | Number | Set size of point objects |
   * | shadows | Boolean | Enable / disable shadows for rendering |
   *
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSettings}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSetting}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#updateSettingsAsync}
   * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSettingDefinitions}
   *
   * @param {String} key - name of setting
   * @param {Object} val - new value of setting
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse whose data property will be true if setting could be changed, false if not.
   */
  this.updateSettingAsync = function() {};

  /**
    * Update a bunch of settings according to the enumerable properties of an object, if allowed.
    *
    * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSettings}
    * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSetting}
    * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#updateSettingAsync}
    * @see {@link module:ApiInterfaceV2~ApiInterfaceV2#getSettingDefinitions}
    *
    * @param {Object} settings - object whose own properties should be used as settings
    * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse whose data property will be set to a copy of the input object, whose property values are replaced by true or false depending on whether the corresponding setting could be updated.
    */
  this.updateSettingsAsync = function() {};

  /**
    * Persistently store current state of settings.
    * This will fail if there is no plugin providing the capability to store model settings.
    * Instances of the CommPlugin provide this capability in case the model view session allows this.
    * @property {String} [plugin] - Optional runtime id of plugin to use for storing settings. Leave undefined to automatically choose a plugin which supports storing settings.
    * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse whose data property will be true or false depending on success.
    */
  this.saveSettingsAsync = function() {};

  /**
   * Creates asynchronous functions for all synchronous functions. These functions will then return a promise with the result of the individual functions as the data property.
   */
  this.createAsynchronousFunctions = function () {};

  /**
   * Get the runtime id used for this API object.
   * The runtime id is used by default as the namespace (= the first part of the scene path) when manipulating the scene (adding and removing assets, etc).
   *
   * The runtime id can optionally be specified as a setting when creating the API object, otherwise a random one will be created.
   * ```
   * // container for the viewer, typically this is a <div>
   * var _container = document.getElementById('sdv-container');
   *
   * // viewer settings example, using a given API runtime id
   * var _viewerSettings = {
   *   // container to use
   *   container: _container,
   *   // we might want to use a specific runtime id for the API v2 object the constructor returns
   *   runtimeId: 'My_runtime_id',
   * };
   *
   * // create the viewer, get back an API v2 object
   * var api = new SDVApp.ParametricViewer(_viewerSettings);
   * console.log(api.getRuntimeId()); // shows 'My_runtime_id'
   *
   * // further API v2 objects can be created if needed:
   * var api_other = api.getApiV2({runtimeId: 'My_other_runtime_id'});
   * console.log(api_other.getRuntimeId()); // shows 'My_other_runtime_id'
   * ```
   *
   * @return {String} runtime id used for API instance, should be unique
   */
  this.getRuntimeId = function() {};

};

this.utils = {};

module.exports = ApiInterfaceV2;

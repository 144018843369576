let BeautyRenderHandler = function (___settings, ___handlers) {
  const THREE = require('../../../externals/three'),
        SHADERS = require('../shaders/ShaderFile'),
        MESSAGE_PROTOTYPE = require('../../../shared/messages/MessagePrototype'),
        MESSAGING_CONSTANTS = require('../../../shared/constants/MessagingConstants'),
        BEAUTY_RENDER_BLENDING_ID = 'beautyRenderBlending',
        _settings = ___settings.settings,
        _scene = ___settings.scene,
        _handlers = ___handlers,
        _renderer = ___settings.renderer;

  let that,
      _pixelRatio = _renderer.getPixelRatio(),
      _width = _renderer.getSize().width * _pixelRatio,
      _height = _renderer.getSize().height * _pixelRatio,
      _lodBlackListed = false,
      _isIOSDevice = false,
      _ssaaPass, _saoPass,
      _hiddenRenderTarget, _standardRenderTarget, _ssaaRenderTarget, _aoRenderTarget,
      _blendShader, _blendTimeStart, _blendTimeEnd,
      _blendAmount = 0,
      _quadCamera, _quadScene, _quad;

  class BeautyRenderHandler {

    constructor() {
      that = this;

      if (/(android)/i.test(navigator.userAgent) && navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
        _lodBlackListed = true;

      // see https://stackoverflow.com/questions/58019463/how-to-detect-device-name-in-safari-on-ios-13-while-it-doesnt-show-the-correct
      _isIOSDevice = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream;

      /**
       * The Supersample Anti-Aliasing Render Pass.
       * If soft shadows or the ambient occlusion is avtive this pass is rendered.
       */
      _ssaaPass = new (require('../passes/SSAAPass'))({
        width: _width,
        height: _height
      });

      /**
       * The Scalable Ambient Obscurance Render Pass.
       * This pass is rendered if the ambient occlusion is active.
       */
      _saoPass = new (require('../passes/SaoPass'))({
        settings: _settings.getSection('sao'),
        width: _width,
        height: _height
      });

      // Parameters for the render targets
      let _parameters = {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat,
        stencilBuffer: false
      };

      // The different render targets that are used by the passes
      _hiddenRenderTarget = new THREE.WebGLRenderTarget(_width, _height, _parameters);
      _hiddenRenderTarget.texture.name = 'hidden.rt';
      _standardRenderTarget = _hiddenRenderTarget.clone();
      _standardRenderTarget.texture.name = 'standard.rt';
      _ssaaRenderTarget = _hiddenRenderTarget.clone();
      _ssaaRenderTarget.texture.name = 'ssaa.rt';
      _aoRenderTarget = _hiddenRenderTarget.clone();
      _aoRenderTarget.texture.name = 'ao.rt';

      /**
       * The blend shader that can blend two render targets with a given value over time.
       */
      _blendShader = new THREE.ShaderMaterial({
        uniforms: {
          tStandard: { value: null },
          tBeauty: { value: null },
          size: { type: 'v2', value: new THREE.Vector2(512, 512) },
          amount: { type: 'f', value: 0.0 }
        },
        attributes: ['position', 'uv'],
        vertexShader: SHADERS.basic_vert,
        fragmentShader: SHADERS.blend_frag
      });
      _blendShader.uniforms.tStandard.value = _standardRenderTarget.texture;
      _blendShader.uniforms.tBeauty.value = _ssaaRenderTarget.texture;
      _blendShader.uniforms.size.value.set(_width, _height);
      _blendShader.uniforms.amount.value = 0;

      // The scene that the blend shader renders on.
      // As the blend shader just combines two textures, the scene just has to be a full-screen quad.
      _quadCamera = new THREE.OrthographicCamera(- 1, 1, 1, - 1, 0, 1);
      _quadScene = new THREE.Scene();
      _quad = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), _blendShader);
      _quadScene.add(_quad);
    }


    /**
     * Toogles the soft shadows in all ShapeDiverStandardMaterials.
     * This is done by exchanging the current material for another material.
     * The material with the soft shadows has a different shader version.
     *
     * All values of the material are updated if changes have occured.
     *
     * @param {Boolean} toggle
     */
    _setToggleSoftShadows(toggle) {
      let obj1 = _handlers.threeDManager.helpers.getMeshMaterialObjects(),
          obj2 = _handlers.threeDManager._getExchangeMeshMaterialObjects();

      for(let i = 0, len1 = obj1.length; i < len1; i++) {
        let mesh = obj1[i].mesh,
            mat1 = obj1[i].material,
            mat2;

        if(mat1.type !== 'ShapeDiverStandardMaterial') continue;

        if(mesh.geometry && mesh.geometry.type !== 'TextGeometry'){
          let index = -1;
          for(let j = 0, len2 = obj2.length; j < len2; j++){
            if(obj2[j].mesh === mesh){
              index = j;
              mat2 = obj2[j].material;
              break;
            }
          }

          if(index === -1){
            mat2 = _handlers.materialHandler.getMaterial();
            mat2.deepCopy(mat1);
            mat2.defines.USE_PCSS_SHADOW = '';
            mat2.softShadow = true;
            //mat2.needsUpdate = true;
            index = obj2.length;
            obj2.push({
              mesh: mesh,
              material: mat2
            });
          }

          if (mat1.softShadow !== toggle) {
            obj1[i].material = mat2;
            obj2[index].material = mat1;
            mesh.material = mat2;

            for (let j in mat1) {
              if (!(j === 'uuid' || j === 'defines' || j === 'vertexShader' || j === 'fragmentShader' || j === 'softShadow')) {
                if (mesh.material[j] !== mat1[j]) {
                  mesh.material[j] = mat1[j];
                  //mesh.material.needsUpdate = true;
                }
              }
            }

            // Remove defines that are only in the material that is being switched to
            for (let j in mesh.material.defines) {
              if (j === 'USE_PCSS_SHADOW') continue;
              if (!(mesh.material.defines[j] && mat1.defines[j])) {
                delete mesh.material.defines[j];
                mesh.material.needsUpdate = true;
              }
            }

            // Add all defines that are not in the current material
            for (let j in mat1.defines) {
              if (j === 'USE_PCSS_SHADOW') continue;
              if (!(mesh.material.defines[j] && mat1.defines[j])) {
                mesh.material.defines[j] = mat1.defines[j];
                mesh.material.needsUpdate = true;
              }
            }
          }
        }
      }
    }

    /**
     * Starts the blend process.
     * The standardTarget is the texture that is visible at first,
     * the beautyTarget is the texture that will be blended into.
     *
     * @param {THREE.WebGLRenderTarget} standardTarget The renderTarget which is visible at first
     * @param {THREE.WebGLRenderTarget} beautyTarget The renderTarget which will blended into
     */
    _startBlending(standardTarget, beautyTarget) {
      _handlers.renderingHandler.registerForContinuousRendering(BEAUTY_RENDER_BLENDING_ID, false);
      _blendTimeStart = performance.now();
      _blendAmount = 0.0;

      _blendShader.uniforms.tStandard.value = standardTarget.texture;
      _blendShader.uniforms.tBeauty.value = beautyTarget.texture;

      if (_settings.getSetting('shadows'))
        that._setToggleSoftShadows(false);

      _renderer.render(_scene, _handlers.cameraHandler.camera, _hiddenRenderTarget, true);
    }

    ////////////
    ////////////
    //
    // BeautyRenderHandler API
    //
    ////////////
    ////////////

    /**
     * Renders the scene normally.
     */
    renderStandard() {
      _renderer.render(_scene, _handlers.cameraHandler.camera);
    }
    
    /**
     * Check if beauty rendering is required.
     */
    beautyRenderingActive() {
      const shadows = _settings.getSetting('shadows'),
            ambientOcclusion = _settings.getSetting('ambientOcclusion');

      return (ambientOcclusion && !_isIOSDevice) || shadows;
    }

    /**
     * Renders the scene with the beauty render options.
     *
     * Depending on the settings, the scene will be rendered with soft shadow and/or ambient occlusion to an external render target.
     * In another step this render target will then be blended into the scene.
     */
    renderBeauty() {
      const shadows = _settings.getSetting('shadows'),
            ambientOcclusion = _settings.getSetting('ambientOcclusion');

      // Render the current scene to display changes and then render the exact same to the _standardRenderTarget.
      _renderer.render(_scene, _handlers.cameraHandler.camera);
      _ssaaPass.render(_renderer, _scene, _handlers.cameraHandler.camera, _standardRenderTarget, 2);

      // Turn on the soft shadows if the shadows are displayed in the scene.
      if (shadows)
        that._setToggleSoftShadows(true);

      if (ambientOcclusion && !_isIOSDevice) {
        // send message to notify about start of beauty rendering
        let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.GENERIC, {
          viewportRuntimeId: _handlers.threeDManager.runtimeId,
        });
        _handlers.threeDManager.message(MESSAGING_CONSTANTS.messageTopics.SCENE_RENDER_BEAUTY_START, m);

        // For ambient occlusion, first render the anti-aliased scene and then rendered the ambient occlusion above it.
        _ssaaPass.render(_renderer, _scene, _handlers.cameraHandler.camera, _ssaaRenderTarget, 2);
        _saoPass.render(_renderer, _scene, _handlers.cameraHandler.camera, _ssaaRenderTarget, _aoRenderTarget);

        _renderer.render(_scene, _handlers.cameraHandler.camera, _hiddenRenderTarget, true);

        // Start the blend process to blend from the _standardRenderTarget to the _aoRenderTarget.
        that._startBlending(_standardRenderTarget, _aoRenderTarget);
      } else if (shadows) {
        // send message to notify about start of beauty rendering
        let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.GENERIC, {
          viewportRuntimeId: _handlers.threeDManager.runtimeId,
        });
        _handlers.threeDManager.message(MESSAGING_CONSTANTS.messageTopics.SCENE_RENDER_BEAUTY_START, m);

        // For just soft shadows, render the anti-aliased scene.
        _ssaaPass.render(_renderer, _scene, _handlers.cameraHandler.camera, _ssaaRenderTarget, 2);

        _renderer.render(_scene, _handlers.cameraHandler.camera, _hiddenRenderTarget, true);

        // Start the blend process to blend from the _standardRenderTarget to the _ssaaRenderTarget.
        that._startBlending(_standardRenderTarget, _ssaaRenderTarget);
      } else {
        // If all settings are disabled, render the normally.
        _renderer.render(_scene, _handlers.cameraHandler.camera);
      }
    }

    /**
     * Render the blend shader to blend one renderTarget into another.
     * The _blendAmount determines how much the second render target is visible. (1 for full visibility, 0 for none)
     */
    renderBlending() {
      // Update the blend amount in the shader and render the scene
      _blendShader.uniforms.amount.value = _blendAmount;
      _renderer.render(_quadScene, _quadCamera, null, true);

      // Update the _blendAmount
      _blendTimeEnd = performance.now();
      let deltaTime = (_blendTimeEnd - _blendTimeStart) / 1000.0;
      _blendTimeStart = _blendTimeEnd;
      _blendAmount = _blendAmount + Math.min((deltaTime / 1.5), 0.1);

      // Abort if the _blendAmount has reached >= 1
      if (_blendAmount >= 1.0) {
        _blendAmount = 0.0;
        _handlers.renderingHandler.unregisterForContinuousRendering(BEAUTY_RENDER_BLENDING_ID);
        // send message to notify about finalized beauty rendering
        let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.GENERIC, {          
          viewportRuntimeId: _handlers.threeDManager.runtimeId,
        });
        _handlers.threeDManager.message(MESSAGING_CONSTANTS.messageTopics.SCENE_RENDER_BEAUTY_END, m);
      }
    }

    renderHidden() {
      // The viewport was just destroyed, this is the last render call
      if(!_handlers.threeDManager) return;

      // Don't render if there is no camera
      if (!(_handlers.cameraHandler && _handlers.cameraHandler.camera)) return;

      _handlers.threeDManager.helpers.toggleViewport(true);
      _renderer.render(_scene, _handlers.cameraHandler.camera, _hiddenRenderTarget);
      _handlers.threeDManager.helpers.toggleViewport(false);
    }

    /**
     * This function updates all custom uniforms.
     * The uniforms from three.js are updated automatically within their system.
     * We have to do the same for out uniforms.
     * Some checks are included to guarantee the right behaviour.
     *
     * IMPORTANT: Has to be called before every render call!
     */
    updateCustomUniforms() {
      _scene.traverse(function (object) {
        if (object.material) {
          if (object.material.type === 'ShapeDiverStandardMaterial') {

            object.material.uniforms.lightWorldSize.value = object.material.lightWorldSize;
            object.material.uniforms.lightFrustum.value = object.material.lightFrustum;
            object.material.uniforms.shadowOpacity.value = object.material.shadowOpacity;
            object.material.uniforms.lightReflectivity.value = object.material.lightReflectivity;
            object.material.uniforms.rgbMap.value = object.material.rgbMap;

            object.material.uniforms.threeDNoiseOpacity.value = object.material.threeDNoiseOpacity;
            object.material.uniforms.threeDNoiseID.value = object.material.threeDNoiseID;
            object.material.uniforms.threeDNoiseScale.value = object.material.threeDNoiseScale;
            object.material.uniforms.threeDNoiseDistanceFade.value = object.material.threeDNoiseDistanceFade;

            if (object.material.threeDNoiseOpacity > 0 && object.material.defines['USE_3D_NOISE_F' + object.material.threeDNoiseID] !== '') {
              if ((0 <= object.material.threeDNoiseID && object.material.threeDNoiseID <= 3) || object.material.threeDNoiseID === 999) {
                for (let key in object.material.defines) {
                  if (key.indexOf('USE_3D_NOISE_F') === 0)
                    delete object.material.defines[key];
                }
                object.material.defines['USE_3D_NOISE_F' + object.material.threeDNoiseID] = '';
                object.material.needsUpdate = true;
              }
            }

            if (object.material.threeDNoiseOpacity > 0 && object.material.defines.USE_3D_NOISE !== '') {
              object.material.defines.USE_3D_NOISE = '';
              object.material.needsUpdate = true;
              if (object.material.threeDNoiseID === -1) {
                object.material.threeDNoiseID = 0;
                object.material.uniforms.threeDNoiseID.value = object.material.threeDNoiseID;
                object.material.defines.USE_3D_NOISE_F0 = '';
              }

            } else if (object.material.threeDNoiseOpacity <= 0 && object.material.defines.USE_3D_NOISE === '') {
              for (let key in object.material.defines) {
                if (key.indexOf('USE_3D_NOISE_F') === 0)
                  delete object.material.defines[key];
              }
              delete object.material.defines.USE_3D_NOISE;
              object.material.needsUpdate = true;
            }

            if (object.material.map) {
              object.material.uniforms.uvTransformMap.value = object.material.map.uvTransform;
              object.material.map.matrix.identity();
            }
            if (object.material.specularMap) {
              object.material.uniforms.uvTransformSpecularMap.value = object.material.specularMap.uvTransform;
              object.material.specularMap.matrix.identity();
            }
            if (object.material.normalMap) {
              object.material.uniforms.uvTransformNormalMap.value = object.material.normalMap.uvTransform;
              object.material.normalMap.matrix.identity();
            }
            if (object.material.bumpMap) {
              object.material.uniforms.uvTransformBumpMap.value = object.material.bumpMap.uvTransform;
              object.material.bumpMap.matrix.identity();
            }
            if (object.material.roughnessMap) {
              object.material.uniforms.uvTransformRoughnessMap.value = object.material.roughnessMap.uvTransform;
              object.material.roughnessMap.matrix = new THREE.Matrix3();
            }
            if (object.material.metalnessMap) {
              object.material.uniforms.uvTransformMetalnessMap.value = object.material.metalnessMap.uvTransform;
              object.material.metalnessMap.matrix = new THREE.Matrix3();
            }
            if (object.material.alphaMap) {
              object.material.uniforms.uvTransformAlphaMap.value = object.material.alphaMap.uvTransform;
              object.material.alphaMap.matrix.identity();
            }
            if (object.material.emissiveMap) {
              object.material.uniforms.uvTransformEmissiveMap.value = object.material.emissiveMap.uvTransform;
              object.material.emissiveMap.matrix.identity();
            }

            if (object.material.mapsSize !== 0) {
              object.material.uniforms.mapsSize.value = object.material.mapsSize;

              if (object.material.mapsSize > 0 && object.material.defines['NUM_ADD_MAPS'] !== object.material.mapsSize) {
                object.material.defines['NUM_ADD_MAPS'] = object.material.mapsSize;
                object.material.needsUpdate = true;
              }

              while (object.material.mapPropertyType.length < 5)
                object.material.mapPropertyType.push(-1);
              while (object.material.mapPropertyColor.length < 5)
                object.material.mapPropertyColor.push(new THREE.Color(0x000000));
              while (object.material.uvTransformAddMap.length < 5)
                object.material.uvTransformAddMap.push(new THREE.Matrix3().identity());

              object.material.uniforms.mapPropertyType.value = object.material.mapPropertyType;
              object.material.uniforms.mapPropertyColor.value = object.material.mapPropertyColor;
              object.material.uniforms.uvTransformAddMap.value = object.material.uvTransformAddMap;

              for (let i = 0, len = object.material.mapsSize; i < len; i++) {
                if (i === 0)
                  object.material.uniforms.additionalMaps0.value = object.material.additionalMaps0;
                if (i === 1)
                  object.material.uniforms.additionalMaps1.value = object.material.additionalMaps1;
                if (i === 2)
                  object.material.uniforms.additionalMaps2.value = object.material.additionalMaps2;
                if (i === 3)
                  object.material.uniforms.additionalMaps3.value = object.material.additionalMaps3;
                if (i === 4)
                  object.material.uniforms.additionalMaps4.value = object.material.additionalMaps4;
              }
            } else {
              object.material.uniforms.mapsSize.value = 0;
              if (object.material.defines['NUM_ADD_MAPS'] !== 0) {
                object.material.defines['NUM_ADD_MAPS'] = 0;
                object.material.needsUpdate = true;
              }
            }

            if (_lodBlackListed && object.material.defines.LOD_BLACK_LIST !== '') {
              object.material.defines.LOD_BLACK_LIST = '';
              object.material.needsUpdate = true;
            }
          } else if(object.material.type === 'ShapeDiverGemMaterial') {
            object.material.uniforms.refractionIndex.value = object.material.refractionIndex;
            object.material.uniforms.center.value = object.material.center;
            object.material.uniforms.radius.value = object.material.radius;
            object.material.uniforms.sphericalNormalMap.value = object.material.sphericalNormalMap;
            object.material.uniforms.dispersion.value = object.material.dispersion;
            object.material.uniforms.contrast.value = object.material.contrast;
            object.material.uniforms.gamma.value = object.material.gamma;
            object.material.uniforms.brightness.value = object.material.brightness;
            object.material.uniforms.tracingOpacity.value = object.material.tracingOpacity;
            object.material.uniforms.impurityMap.value = object.material.impurityMap;
            object.material.uniforms.impurityScale.value = object.material.impurityScale;
            object.material.uniforms.colorTransferBegin.value = object.material.colorTransferBegin;
            object.material.uniforms.colorTransferEnd.value = object.material.colorTransferEnd;
            object.material.inverseModelMatrix = new THREE.Matrix4().getInverse(object.matrixWorld);
            object.material.uniforms.inverseModelMatrix.value = object.material.inverseModelMatrix;
            object.material.inverseTransposeModelMatrix = new THREE.Matrix3().getNormalMatrix(new THREE.Matrix4().getInverse(object.matrixWorld).transpose());
            object.material.uniforms.inverseTransposeModelMatrix.value = object.material.inverseTransposeModelMatrix;
            if (object.material.defines['DEPTH'] != object.material.depth) {
              object.material.defines['DEPTH'] = object.material.depth;
              object.material.needsUpdate = true;
            }
            if (object.material.defines['DISPERSION'] === '' && object.material.dispersion === 0.0) {
              delete object.material.defines['DISPERSION'];
              object.material.needsUpdate = true;
            }
            if (object.material.defines['DISPERSION'] !== '' && object.material.dispersion !== 0.0) {
              object.material.defines['DISPERSION'] = '';
              object.material.needsUpdate = true;
            }
            if (object.material.defines['USE_IMPURITY_MAP'] === '' && object.material.impurityMap === null) {
              delete object.material.defines['USE_IMPURITY_MAP'];
              object.material.needsUpdate = true;
            }
            if (object.material.defines['USE_IMPURITY_MAP'] !== '' && object.material.impurityMap !== null) {
              object.material.defines['USE_IMPURITY_MAP'] = '';
              object.material.needsUpdate = true;
            }
          }
        }
      });
    }

    /**
     * Sets the size of all things related to beauty rendering.
     *
     * @param {Number} width The new width
     * @param {Number} height The new height
     */
    setSize(width, height) {
      let pr = _renderer.getPixelRatio();
      width *= pr;
      height *= pr;
      _saoPass.setSize(width, height);
      _ssaaPass.setSize(width, height);
      _hiddenRenderTarget.setSize(width, height);
      _standardRenderTarget.setSize(width, height);
      _ssaaRenderTarget.setSize(width, height);
      _aoRenderTarget.setSize(width, height);
      _blendShader.uniforms.size.value.set(width, height);
    }

  }

  return new BeautyRenderHandler(___settings);
};

module.exports = BeautyRenderHandler;

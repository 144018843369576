let SceneGeometryManager = function (viewportManager, ___settings) {
  let that, _viewportManager,
      _scene, _geometryNode,
      _meshMaterialObjects = [],
      _anchorObjects = [],
      _hiddenPaths = [],
      _threeMatrix = new THREE.Matrix4(),
      _tempMatrix = new THREE.Matrix4(),
      _threeMatrices = [];

  const THREE = require('../externals/three'),
        GLOBAL_UTILS = require('../shared/util/GlobalUtils'),
        PATH_UTILS = new (require('../shared/util/PathUtils'))(),
        GEOMETRY_ADD_ID = 'geomAddEvent',
        GEOMETRY_REMOVE_ID = 'geomRemoveEvent',
        GEOMETRY_REPLACE_ID = 'geomReplaceEvent';

  require('../3d/controls/DragControls');

  require('../3d/viewport/materials/ShapeDiverStandardMaterial');
  require('../3d/viewport/materials/ShapeDiverGemMaterial');

  class SceneGeometryManager {
    constructor(viewportManager, ___settings) {
      that = this;
      _viewportManager = viewportManager;

      // activate THREE caching across all loaders
      THREE.Cache.enabled = true;

      /**
       * The actual scene that will be rendered.
       * Stores the camera, lights and all objects in the scene.
       */
      _scene = new THREE.Scene();
      _geometryNode = new THREE.Object3D();
      _geometryNode.computeSceneBoundingBox = function () {
        let b = new THREE.Box3();
        this.traverse(function (obj) {
          let p = PATH_UTILS.getObjectPath(obj);
          if(p && !p.includes('lightHelper'))
            b.union(new THREE.Box3().setFromObject(obj));
        });

        if (b.min.distanceTo(b.max) == 0)
          b = new THREE.Box3(new THREE.Vector3(-1, -1, -1), new THREE.Vector3(1, 1, 1));

        return b;
      };

      _geometryNode.computeBoundingBox = function (scenePaths) {
        let b = new THREE.Box3();

        if (!Array.isArray(scenePaths) || scenePaths.length === 0)
          return this.computeSceneBoundingBox();

        for (let i = 0, len = scenePaths.length; i < len; i++) {
          let obj3D = PATH_UTILS.getPathObject(this, scenePaths[i] + '');
          if (obj3D)
            b.union(new THREE.Box3().setFromObject(obj3D));
        }
        if (b.min.distanceTo(b.max) == 0)
          b = this.computeSceneBoundingBox();

        return b;
      };


      _scene.add(_geometryNode);
      PATH_UTILS.setScene(_geometryNode);

      ////////////
      ////////////
      //
      // Global prototype
      //
      ////////////
      ////////////

      require('../shared/mixins/GlobalMixin').call(that);

      ////////////
      ////////////
      //
      // Logging
      //
      // register standard logging functions,
      // and replace default logging to console by pub/sub scheme
      //
      ////////////
      ////////////

      // mix in global logging functions, or use the ones we got
      if (___settings && ___settings.loggingHandler) {
        GLOBAL_UTILS.inject(___settings.loggingHandler, that);
      } else {
        require('../shared/mixins/LoggingMixin').call(that);
      }

      ////////////
      ////////////
      //
      // Settings
      //
      ////////////
      ////////////

      require('../shared/mixins/SettingsMixin').call(that, ___settings.settings, {});

      ////////////
      ////////////
      //
      // General messaging via pub/sub
      //
      ////////////
      ////////////

      if (___settings && ___settings.messagingHandler) {
        GLOBAL_UTILS.inject(___settings.messagingHandler, that);
      }
    }

    _getMaterials(properties, geometryObject, version, overrideColor, vertexColors, hasTextureCoordinates) {
      let materials = _viewportManager.threeDManager.materialHandler.getMaterial(properties, geometryObject, version, overrideColor, vertexColors);
      if (materials.includes(null) || materials.includes(undefined))
        that.warn('The creation of a material for at least one viewport failed.');

      if (!hasTextureCoordinates) {
        for (let i = 0, len = materials.length; i < len; i++) {
          materials[i].map = null;
          materials[i].alphaMap = null;
          materials[i].aoMap = null;
          materials[i].bumpMap = null;
          materials[i].displacementMap = null;
          materials[i].emissiveMap = null;
          materials[i].normalMap = null;
          materials[i].metalnessMap = null;
          materials[i].roughnessMap = null;
        }
      }
      return materials;
    }

    _setMeshMaterialCombo(mesh, materials, properties, hasTextureCoordinates, overrideColor, vertexColors) {
      _viewportManager.threeDManager.addMesh(mesh, materials, properties);
      _meshMaterialObjects.push({
        mesh: mesh,
        properties: properties,
        hasTextureCoordinates: hasTextureCoordinates,
        overrideColor: overrideColor,
        vertexColors: vertexColors,
      });
    }

    _addMesh(geometryObject, properties, version, hasTextureCoordinates, overrideColor, vertexColors) {

      if (geometryObject.transparentMeshHelper) {

        properties.renderAO = false;
        /**
         * Back side first
         */
        properties.side = THREE.BackSide;
        let materials = that._getMaterials(properties, geometryObject.children[0], version, overrideColor, vertexColors, hasTextureCoordinates);
        geometryObject.children[0].castShadow = true;
        if (properties.opacity && properties.opacity > 0.5) geometryObject.children[0].receiveShadow = true;
        that._setMeshMaterialCombo(geometryObject.children[0], materials, properties, hasTextureCoordinates, overrideColor, vertexColors);

        /**
         * Front side after
         */
        properties.side = THREE.FrontSide;
        materials = that._getMaterials(properties, geometryObject.children[1], version, overrideColor, vertexColors, hasTextureCoordinates);
        geometryObject.children[1].castShadow = true;
        if (properties.opacity && properties.opacity > 0.5) geometryObject.children[1].receiveShadow = true;
        that._setMeshMaterialCombo(geometryObject.children[1], materials, properties, hasTextureCoordinates, overrideColor, vertexColors);

        properties.side = THREE.DoubleSide;

      } else if (geometryObject.meshHelper) {
        let materials = that._getMaterials(properties, geometryObject.children[0], version, overrideColor, vertexColors, hasTextureCoordinates);
        geometryObject.children[0].castShadow = true;
        geometryObject.children[0].receiveShadow = true;
        that._setMeshMaterialCombo(geometryObject.children[0], materials, properties, hasTextureCoordinates, overrideColor, vertexColors);
      } else {
        let materials = that._getMaterials(properties, geometryObject, version, overrideColor, vertexColors, hasTextureCoordinates);
        geometryObject.castShadow = true;
        geometryObject.receiveShadow = true;
        that._setMeshMaterialCombo(geometryObject, materials, properties, hasTextureCoordinates, overrideColor, vertexColors);

      }
    }

    _removeMesh(mesh) {
      _viewportManager.threeDManager.removeMesh(mesh);

      for (let i = 0, len = _meshMaterialObjects.length; i < len; i++)
        if (_meshMaterialObjects[i].mesh === mesh) {
          _meshMaterialObjects.splice(i, 1);
          return;
        }
    }

    _addAnchor(geometryObject, properties) {
      _anchorObjects.push({
        object: geometryObject,
        properties: properties
      });

      _viewportManager.threeDManager.addAnchor(geometryObject, properties);
    }

    _removeAnchor(object) {
      _viewportManager.threeDManager.removeAnchor(object);
      for (let i = 0, len = _anchorObjects.length; i < len; i++)
        if (_anchorObjects[i].object === object) {
          _anchorObjects.splice(i, 1);
          return;
        }
    }

    getScene() {
      return _scene;
    }

    getGeometryNode() {
      return _geometryNode;
    }

    getPathUtils() {
      return PATH_UTILS;
    }

    getMeshMaterialObjects() {
      return _meshMaterialObjects;
    }

    /**
     * Adds new geometry to the scene
     * @param  {String} path - Path in dot-separated format describing the global path under which the geometry is to be added
     * @param  {module:OutputVersion~GeometryData[]} geometry - Geometry data objects with relative path, geometry and material
     * @param {Object} [options] - Execution options
     * @param {Number} [options.duration] - Duration of fadein in msec
     * @param {String} [options.interactionGroup]
     * @param {String} [options.interactionMode='sub']
     * @param {String} [options.dragPlaneNormal]
     * @param {String} [options.name] - Human readable name of this geometry, will be added as property SDName to the object at the specified path
     * @param {String} [options.visible] - {@link https://threejs.org/docs/#api/core/Object3D.visible visible} property of the object at the specified path
     * @return {Promise} Resolves if the geometry was successfully added
     */
    addGeometry(path, geometry, options, version) {
      _viewportManager.threeDManager.renderingHandler.registerForContinuousRendering(GEOMETRY_ADD_ID);

      // parameter sanity check
      options = options || {};

      // if there is something at this path, we abort
      // this function is not replacing geometry
      let previousPathObject = PATH_UTILS.getPathObject(_geometryNode, path);
      if (previousPathObject != null) {
        _viewportManager.threeDManager.renderingHandler.unregisterForContinuousRendering(GEOMETRY_ADD_ID);
        return Promise.reject(new Error('Geometry already exists at that path: ' + path));
      }
      let globalPathObject = PATH_UTILS.ensurePath(_geometryNode, path);
      if (GLOBAL_UTILS.typeCheck(options.name, 'string')) {
        globalPathObject.SDName = options.name;
      }
      if (GLOBAL_UTILS.typeCheck(options.visible, 'boolean')) {
        globalPathObject.visible = options.visible;
      }
      else {
        globalPathObject.visible = true;
      }
      let hasSDBoundingBox = options.hasOwnProperty('boundingBox');
      if (hasSDBoundingBox) {
        globalPathObject.SDBoundingBox = options.boundingBox;
      }

      for (let g of geometry) {

        ['geometry', 'path', 'type', 'material'].forEach(function (attr) {
          if (!g.hasOwnProperty(attr)) {
            _viewportManager.threeDManager.renderingHandler.unregisterForContinuousRendering(GEOMETRY_ADD_ID);
            return Promise.reject(new Error('GeometryData object is missing property ' + attr));
          }
        });

        let relativePathObject = PATH_UTILS.ensurePath(globalPathObject, g.path);

        // material just to get the right color for points and lines
        let color;
        if (g.material.color) {
          color = new THREE.Color(g.material.color);
        } else {
          color = new THREE.Color();
        }

        if (g.color)
          color.set(g.color.toRgbString());

        let geometryObject = null,
            hasTextureCoordinates = false;
        try {
          switch (g.type) {
            case 'mesh':
              // check for texture coordinates
              if (g.geometry instanceof THREE.BufferGeometry) {
                if (g.geometry.hasOwnProperty('attributes') && g.geometry.attributes.hasOwnProperty('uv')) {
                  hasTextureCoordinates = true;
                }
              } else {
                if (g.geometry instanceof THREE.Geometry) {
                  if (g.geometry.faceVertexUvs.length > 0) {
                    hasTextureCoordinates = true;
                  }
                }
              }

              // compute vertex normals
              // TODO: improve this, it is expensive. it is currently only needed for ambient occlusion
              if (g.geometry instanceof THREE.BufferGeometry) {
                if (!g.geometry.attributes.hasOwnProperty('normal') || g.geometry.attributes.normal == null) {
                  g.geometry.computeVertexNormals();
                }
              } else {
                if (g.geometry instanceof THREE.Geometry) {
                  if (g.geometry.faces.length > 0) {
                    let f = g.geometry.faces[0];
                    if (f.vertexNormals == null || f.vertexNormals.length == 0) {
                      g.geometry.computeFaceNormals();
                      g.geometry.computeVertexNormals();
                    }
                  }
                }
              }

              geometryObject = new THREE.Object3D();
              geometryObject.meshHelper = true;

              if(!g.geometry.boundingSphere) {
                g.geometry.computeBoundingSphere();
                g.geometry.boundingSphere.realCenter = g.geometry.boundingSphere.center.clone();
                g.geometry.translate(-g.geometry.boundingSphere.center.x, -g.geometry.boundingSphere.center.y, -g.geometry.boundingSphere.center.z);
              }

              if (g.material && g.material.transparent === true && g.material.side === THREE.DoubleSide &&
                g.material.materialType !== 'gem') {
                geometryObject.transparentMeshHelper = true;

                let m1 = new THREE.Mesh(g.geometry, new THREE.MeshBasicMaterial());
                m1.position.set(g.geometry.boundingSphere.realCenter.x, g.geometry.boundingSphere.realCenter.y, g.geometry.boundingSphere.realCenter.z);
                let m2 = new THREE.Mesh(g.geometry, new THREE.MeshBasicMaterial());
                m2.position.set(g.geometry.boundingSphere.realCenter.x, g.geometry.boundingSphere.realCenter.y, g.geometry.boundingSphere.realCenter.z);
                geometryObject.add(m1);
                geometryObject.add(m2);
              } else {
                let m = new THREE.Mesh(g.geometry, new THREE.MeshBasicMaterial());
                m.position.set(g.geometry.boundingSphere.realCenter.x, g.geometry.boundingSphere.realCenter.y, g.geometry.boundingSphere.realCenter.z);
                geometryObject.add(m);
              }
              break;
            case 'points':
              geometryObject = new THREE.Points(g.geometry, new THREE.PointsMaterial({ color: color, size: 1 }));
              break;
            case 'line':
              geometryObject = new THREE.Line(g.geometry, g.material.line.type === 'dashed' ?
                new THREE.LineDashedMaterial({ color: color, linewidth: g.material.line.lineWidth, dashSize: g.material.line.dashSize, gapSize: g.material.line.gapSize, scale: g.material.line.scale })
                : new THREE.LineBasicMaterial({ color: color, linewidth: g.material.line.lineWidth }));
              if(g.material.line.type === 'dashed') geometryObject.computeLineDistances();
              break;
            case 'linesegments':
              geometryObject = new THREE.LineSegments(g.geometry, g.material.line.type === 'dashed' ?
                new THREE.LineDashedMaterial({ color: color, linewidth: g.material.line.lineWidth, dashSize: g.material.line.dashSize, gapSize: g.material.line.gapSize, scale: g.material.line.scale })
                : new THREE.LineBasicMaterial({ color: color, linewidth: g.material.line.lineWidth }));
              if(g.material.line.type === 'dashed') geometryObject.computeLineDistances();
              break;
            case 'lineloop':
              geometryObject = new THREE.LineLoop(g.geometry, g.material.line.type === 'dashed' ?
                new THREE.LineDashedMaterial({ color: color, linewidth: g.material.line.lineWidth, dashSize: g.material.line.dashSize, gapSize: g.material.line.gapSize, scale: g.material.line.scale })
                : new THREE.LineBasicMaterial({ color: color, linewidth: g.material.line.lineWidth }));
              if(g.material.line.type === 'dashed') geometryObject.computeLineDistances();
              break;
            case 'anchor':
              geometryObject = new THREE.Object3D();
              geometryObject.position.set(g.geometry.position.x, g.geometry.position.y, g.geometry.position.z);
              geometryObject.type = 'Anchor';
              geometryObject.material = {};
              break;
            case 'tag3d':
            default:
            // CAUTION!
            // moved 'continue' statement from here downwards, due to expo.io/react native,
            // which throws an exception "'continue' is only valid inside a loop statement."
            //continue;
          }
        } catch (e) {
          _viewportManager.threeDManager.renderingHandler.unregisterForContinuousRendering(GEOMETRY_ADD_ID);
          return Promise.reject(e);
        }

        // moved 'continue' statement here, due to expo.io/react native, see reason described above
        if (!geometryObject)
          continue;

        if (g.hasOwnProperty('initialMatrix'))
          geometryObject.applyMatrix(g.initialMatrix);

        // store material definition in object
        for (let i = 0, len = geometryObject.children.length; i < len; i++)
          geometryObject.children[i].SDMaterialDefinition = g.material;

        relativePathObject.add(geometryObject);

        // Question Alex to Michael: when replacing geometry using duration > 0, one typically can see
        // the new geometry appear flicker for a very short time before being faded in
        // I suspect this has to do with the geometry being added without the initial opacity of 0 applied for fading in
        // Can we work around this, in order to remove the flickering?
        if (geometryObject.meshHelper) {
          that._addMesh(geometryObject, g.material, version, hasTextureCoordinates, g.color, g.geometry.attributes && g.geometry.attributes.hasOwnProperty('color'));
        } else if (geometryObject.type === 'Anchor') {
          that._addAnchor(geometryObject, Object.assign({ path: path + relativePathObject.SDLocalPath }, g.geometry));
        }

        if (typeof options.duration === 'number' && options.duration > 0) {
          for (let i = 0, len = geometryObject.children.length; i < len; i++) {
            geometryObject.children[i].material.transparent = true;
            geometryObject.children[i].material.opacity = 0;
          }
        }
      }

      _viewportManager.threeDManager.updateInteractions(path, options);

      that.evaluateGeometryVisibility();

      if (!hasSDBoundingBox) {
        let cbb = new THREE.Box3();
        cbb.setFromObject(globalPathObject);
        globalPathObject.SDBoundingBox = cbb;
      }

      _viewportManager.threeDManager.adjustScene();

      if (typeof options.duration === 'number' && options.duration > 0) {
        return _viewportManager.threeDManager.fadeIn(path, options.duration).then(function () {
          _viewportManager.threeDManager.renderingHandler.unregisterForContinuousRendering(GEOMETRY_ADD_ID);
        });
      }

      _viewportManager.threeDManager.renderingHandler.unregisterForContinuousRendering(GEOMETRY_ADD_ID);
      return Promise.resolve();
    }


    /**
     * Removes geometry from the scene
     * @param  {String} path    All geometry at and below this path will be removed
     * @param  {Object} [options] Execution options
     * @param {Number} [options.duration] Duration of fade out in msec
     * @return {Promise} Resolves if there is no geometry at the given path after the operation
     */
    removeGeometry(path, options) {
      let scope = 'ThreeDManager.removeGeometry';

      // parameter sanity check
      options = options || {};

      // if the path does not exist, we consider it success and resolve
      let obj = PATH_UTILS.getPathObject(_geometryNode, path);
      if (obj == null) {
        return Promise.resolve();
      }
      _viewportManager.threeDManager.renderingHandler.registerForContinuousRendering(GEOMETRY_REMOVE_ID);

      // geometry removal
      const removeOldGeometry = () => {
        _viewportManager.threeDManager.removeFromInteractions(path);
        _viewportManager.threeDManager.adjustScene();
        _viewportManager.threeDManager.renderingHandler.unregisterForContinuousRendering(GEOMETRY_REMOVE_ID);

        obj.traverse(function (obj) {
          if (obj.type === 'Mesh') {
            that._removeMesh(obj);
            obj.material.dispose();
            obj.geometry.dispose();
          } else if (obj.type === 'Anchor') {
            that._removeAnchor(obj);
          }
        });

        // Path not deleted
        if (!PATH_UTILS.deletePath(_geometryNode, path)) {
          let msg = 'Could not delete scene path ' + path;
          that.error(scope, msg);
          return Promise.reject(new Error(msg));
        }

        return Promise.resolve();
      };

      // in case of fade in out animations
      if (typeof options.duration === 'number' && options.duration > 0) {

        return _viewportManager.threeDManager.fadeOut(path, options.duration).then(function () {
          return removeOldGeometry();
        }).catch(function (err) {
          let msg = 'Exception when removing geometry at path ' + path;
          that.error(scope, msg, err);
          return Promise.reject(err);
        });

      }

      return removeOldGeometry();
    }

    /**
     * Adds new geometry to the scene
     * @param  {String} path - Path in dot-separated format describing the global path under which the geometry is to be replaced
     * @param  {module:OutputVersion~GeometryData[]} geometry - Geometry data objects with relative path, geometry and material
     * @param {Object} [options] - Execution options
     * @param {Number} [options.duration] - Duration of replacement fading in msec
     * @param {String} [options.visible] - {@link https://threejs.org/docs/#api/core/Object3D.visible visible} property of the object at the specified path
     * @return {Promise<Boolean>} Resolves if the geometry was successfully added
     */
    replaceGeometry(path, geometry, options, version) {
      // parameter sanity check
      options = options || {};

      _viewportManager.threeDManager.renderingHandler.registerForContinuousRendering(GEOMETRY_REPLACE_ID);

      // see if the object or sub objects are selected and re-select them afterwards
      // #SS-923 handle this case if there are other viewports that are not default, but also include interactions, they have to be included here
      let apis = _viewportManager.getApis(),
          selectionPerManager = {};

      for (let i = 0, len1 = apis.length; i < len1; i++) {
        let currentlySelectedPaths = apis[i].getSelected(),
            relevantSelected = [];

        if (currentlySelectedPaths.includes(path)) {
          // global
          relevantSelected.push(path);
        } else {
          // sub
          let obj = PATH_UTILS.getPathObject(_geometryNode, path);
          if (obj !== null) {
            for (let j = 0, len2 = currentlySelectedPaths.length; j < len2; j++) {
              let p = currentlySelectedPaths[j];
              if (PATH_UTILS.getPathObject(obj, p) !== null)
                relevantSelected.push(p);
            }
          }
        }
        selectionPerManager[apis[i].getViewportRuntimeId()] = relevantSelected;
      }


      let internalOptions = {};
      if (typeof options.duration === 'number' && options.duration > 0) {
        internalOptions = { duration: 0.5 * options.duration };
      }
      ['interactionGroup', 'interactionMode', 'dragPlaneNormal', 'visible'].forEach(function (attr) {
        if (options.hasOwnProperty(attr)) internalOptions[attr] = options[attr];
      });

      //TODO #SS-923: make sure draggable / hoverable / selectable status is kept if not explicitly changes

      return that.removeGeometry(path, internalOptions).catch(function (err) {
        _viewportManager.threeDManager.renderingHandler.unregisterForContinuousRendering(GEOMETRY_REPLACE_ID);
        return Promise.reject(err);
      }).then(function () { that.addGeometry(path, geometry, internalOptions, version); }).catch(function (err) {
        _viewportManager.threeDManager.renderingHandler.unregisterForContinuousRendering(GEOMETRY_REPLACE_ID);
        return Promise.reject(err);
      }).then(function () {
        // set all the paths that were selected as selected again
        let defaultThreeDManagers = _viewportManager.getApis();
        for (let i = 0, len = defaultThreeDManagers.length; i < len; i++) {
          let m = defaultThreeDManagers[i],
              s = selectionPerManager[m.runtimeId];
          if (s && s.length != 0)
            m.api.updateSelected(m.api.getSelected().concat(s));
        }

        _viewportManager.threeDManager.renderingHandler.unregisterForContinuousRendering(GEOMETRY_REPLACE_ID);
      });
    }

    /**
     * Toggles the shadows for an object with a specific path.
     * @param {String} path The path of the object
     * @param {boolean} bCast The toggle for this operation
     *
     * @return {boolean} True if the object was found
     */
    setToggleCastShadow(path, bCast) {
      let obj = PATH_UTILS.getPathObject(_geometryNode, path);
      if (obj == null) return false;
      obj.castShadow = bCast;
      obj.traverse(function (object) {
        object.castShadow = bCast;
      });
      _viewportManager.threeDManager.renderingHandler.updateShadowMap();
      _viewportManager.api.render();
      return true;
    }

    /**
     * Given an object, get its path in the scene, which could be used to retrieve the object using getPathObject
     * @param  {THREE.Object3D} obj - The base object
     * @return  {String} Path to the object, starting at base object, null if not found
     */
    getObjectPath(obj) {
      return PATH_UTILS.getObjectPath(obj);
    }

    /**
     * Given a base object, pursue a dot-separated path to see if there is an object there
     * @param  {THREE.Object3D} [obj] - Optional base object to start searching from, if not specified the complete scene is used
     * @param  {String} path - Path to find object, starting at base object
     * @return {THREE.Object3D} The target object if it exists, null otherwise
     */
    getPathObject(obj, path) {
      return PATH_UTILS.getPathObject(obj, path);
    }

    getBoundingBox(path) {
      let obj = path ? PATH_UTILS.getPathObject(_geometryNode, path) : _geometryNode;
      if (obj) {
        // we don't set the initial bounding box from obj on purpose, because this already includes all children, whether visible or not
        let box = new THREE.Box3();
        obj.traverseVisible(function (o) {
          let p = PATH_UTILS.getObjectPath(o);
          if(p && !p.includes('lightHelpers')) {
            box.union(new THREE.Box3().setFromObject(o));
          }
        });
        return { min: box.min, max: box.max };
      } else {
        return null;
      }
    }

    setPluginTransformation(plugin, matrix, preventSceneUpdate) {
      let obj = PATH_UTILS.getPathObject(_geometryNode, plugin);
      if (!obj) return false;

      if (!obj.originalMatrix)
        obj.originalMatrix = obj.matrix.clone();

      if(!obj.transformationMatrices)
        obj.transformationMatrices = [];


      if((!Array.isArray(matrix[0]) && matrix[0] !== null) || matrix.isMatrix4)
        matrix = [matrix];

      _threeMatrix.identity();

      for (let i = 0, len = matrix.length > obj.transformationMatrices.length ? matrix.length : obj.transformationMatrices.length; i < len; i++){
        if(matrix[i]) {
          obj.transformationMatrices[i] = matrix[i].isMatrix4 ? matrix[i] : _tempMatrix.clone().set(...matrix[i]);
        } else if(!obj.transformationMatrices[i]) {
          obj.transformationMatrices[i] = _tempMatrix.clone();
        }
        _threeMatrix.premultiply(obj.transformationMatrices[i]);
      }

      obj.matrix.identity();
      obj.applyMatrix(_threeMatrix);

      if(!preventSceneUpdate)
        _viewportManager.threeDManager.adjustScene();

      _viewportManager.api.render();
      return true;
    }

    getPluginTransformation(plugin) {
      let obj = PATH_UTILS.getPathObject(_geometryNode, plugin);
      if (!obj) {
        return [new THREE.Matrix4()];
      }

      if(obj.transformationMatrices && obj.transformationMatrices.length > 0) {
        let tmp = [];
        for(let  i = 0, len = obj.transformationMatrices.length; i < len; i++)
          tmp[i] = obj.transformationMatrices[i].clone();
        return tmp;
      } else {
        return [obj.matrix.clone()];
      }
    }

    applyPluginTransformation(plugin, matrix, preventSceneUpdate) {
      let obj = PATH_UTILS.getPathObject(_geometryNode, plugin);
      if (!obj.originalMatrix)
        obj.originalMatrix = obj.matrix.clone();

      if(!obj.transformationMatrices)
        obj.transformationMatrices = [];

      if((!Array.isArray(matrix[0]) && matrix[0] !== null) || matrix.isMatrix4)
        matrix = [matrix];

      _threeMatrix.identity();

      for (let i = 0, len = matrix.length > obj.transformationMatrices.length ? matrix.length : obj.transformationMatrices.length; i < len; i++){
        if(matrix[i])
          _threeMatrices[i] = matrix[i].isMatrix4 ? matrix[i] : _tempMatrix.clone().set(...matrix[i]);

        if(obj.transformationMatrices[i]) {
          if(matrix[i])
            obj.transformationMatrices[i].multiply(_threeMatrices[i]);
        } else {
          if(matrix[i]) {
            obj.transformationMatrices[i] = _threeMatrices[i];
          } else {
            obj.transformationMatrices[i] = _tempMatrix.clone();
          }
        }
        _threeMatrix.premultiply(obj.transformationMatrices[i]);
      }

      obj.matrix.identity();
      obj.applyMatrix(_threeMatrix);

      if(!preventSceneUpdate)
        _viewportManager.threeDManager.adjustScene();

      _viewportManager.api.render();
      return true;
    }

    resetPluginTransformation(plugin,  preventSceneUpdate) {
      let obj = PATH_UTILS.getPathObject(_geometryNode, plugin);
      if (obj.originalMatrix) {
        obj.matrix.identity();
        obj.applyMatrix(obj.originalMatrix);
        obj.transformationMatrices = [];
      }

      if(!preventSceneUpdate)
        _viewportManager.threeDManager.adjustScene();

      _viewportManager.api.render();
      return true;
    }

    toggleGeometry(show, hide) {
      for (let i = 0; i < show.length; i++) {
        if (GLOBAL_UTILS.typeCheck(show[i], 'string')) {
          let obj = PATH_UTILS.getPathObject(_geometryNode, show[i]);
          if (obj) {
            obj.visible = true;
            obj.hiddenObject = false;
          }
          let index = _hiddenPaths.indexOf(show[i]);
          if (index > -1) _hiddenPaths.splice(index, 1);
        }
      }

      for (let i = 0; i < hide.length; i++) {
        if (GLOBAL_UTILS.typeCheck(hide[i], 'string'))
          if (!_hiddenPaths.includes(hide[i]))
            _hiddenPaths.push(hide[i]);
      }

      that.evaluateGeometryVisibility();
    }

    evaluateGeometryVisibility() {
      for (let i = 0; i < _hiddenPaths.length; i++) {
        let obj = PATH_UTILS.getPathObject(_geometryNode, _hiddenPaths[i]);
        if (obj) {
          obj.visible = false;
          obj.hiddenObject = true;
        }
      }

      _viewportManager.threeDManager.renderingHandler.updateShadowMap();
      _viewportManager.api.render();
    }
  }

  return new SceneGeometryManager(viewportManager, ___settings);
};

module.exports = SceneGeometryManager;

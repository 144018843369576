/**
 * @file Creates a shared interface for all AR implementations.
 *       All described methods must be implemented.
 *
 * @module ArApiInterface
 * @author Alexander Schiftner
 */

/**
  * ### ShapeDiver Viewer - AR API
  *
  * The AR interface is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2 API} of the ShapeDiver Viewer.
  *
  * Prerequisites for the AR interface are to use a supported device, and to run the ShapeDiver Viewer in a supported environment.
  * Currently the ShapeDiver Viewer can be used in AR mode on all devices running iOS which support ARKit, if the viewer is run
  * inside the ShapeDiver App or inside a client-specific App provided by ShapeDiver.
  * Support for devices running Android and ARCore will be added in the near future.
  *
  * The AR interface
  *
  *  * allows to switch the default viewport between orbit and AR mode
  *  * allows to configure behavior of the viewer in AR mode by means of settings
  *  * allows to add and remove handlers for related events
  *  * provides access to low level functionality related to AR
  *  * can be configured using settings with prefix `ar.`, see {@link module:ApiInterfaceV2~ApiInterfaceV2#updateSettingAsync updateSettingAsync}
  *
  * Recommended reading:
  *   * {@link https://developer.apple.com/documentation/arkit ARKit documentation}
  *   * {@link https://developers.google.com/ar/reference/java/arcore/reference/com/google/ar/core/package-summary ARCore documentation}
  *
  * @interface ArApiInterface
  */
class ArApiInterface {
  /**
   * The constructor for the {@link module:ArApiInterface~ArApiInterface}.
   * @constructs module:ArApiInterface~ArApiInterface
   */
  constructor() { }

  /**
  * AR event type definition
  * @typedef {Object} module:ArApiInterface~ArApiInterface#AREvent
  * @property {String} type - type of event as specified when registering an event listener
  * @property {Object} api - Reference to the instance of the API object which was used to register the event listener
  * @property {module:ArApiInterface~ArApiInterface#ARAnchor} [image] - new image anchor, updates to the image anchor will be part of property 'anchors'
  *   * for ARKit see {@link https://developer.apple.com/documentation/arkit/arimageanchor image anchor}
  * @property {Object<String, module:ArApiInterface~ArApiInterface#ARAnchor>} [anchors] - updated anchors, object mapping anchor uuid to anchor
  * @property {String} [tracking] - describes position tracking quality, one of:
  *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate camera tracking status})
  *     * 'notAvailable'
  *     * 'limited'
  *     * 'normal'
  *
  * @property {String} [trackingReason] - describes possible reasons for 'limited' position tracking quality, one of:
  *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate/reason camera tracking reason})
  *     * '' (in case tracking is not 'limited')
  *     * 'initializing'
  *     * 'relocalizing'
  *     * 'excessiveMotion'
  *     * 'insufficientFeatures'
  *
  * @property {String} [worldMapping] - describes how thoroughly the currently visible part of the virtual world has already been mapped, one of:
  *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arframe/worldmappingstatus world mapping status})
  *     * 'notAvailable'
  *     * 'limited'
  *     * 'extending'
  *     * 'mapped'
  *
  * @property {String} [runtimeId] - OBJECT_PLACEMENT events: Runtime id of the plugin whose placement anchor changed
  *
  * @property {String} [anchorId] - OBJECT_PLACEMENT events: Anchor id of the placement anchor
  */

  /**
  * AR status type definition
  * @typedef {Object} module:ArApiInterface~ArApiInterface#ARStatusType
  * @property {String} framework - framework which provides AR functionality, one of
  *  * 'none' (AR functionality not available)
  *  * 'arkit'
  *  * 'arcore'
  *
  * @property {Object<String, module:ArApiInterface~ArApiInterface#ARAnchor>} [anchors] - anchors currently known, object mapping anchor uuid to anchor
  * @property {String} [tracking] - describes position tracking quality, one of:
  *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate camera tracking status})
  *     * 'notAvailable'
  *     * 'limited'
  *     * 'normal'
  *
  * @property {String} [trackingReason] - describes possible reasons for 'limited' position tracking quality, one of:
  *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate/reason camera tracking reason})
  *     * '' (in case tracking is not 'limited')
  *     * 'initializing'
  *     * 'relocalizing'
  *     * 'excessiveMotion'
  *     * 'insufficientFeatures'
  *
  * @property {String} [worldMapping] - describes how thoroughly the currently visible part of the virtual world has already been mapped, one of:
  *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arframe/worldmappingstatus world mapping status})
  *     * 'notAvailable'
  *     * 'limited'
  *     * 'extending'
  *     * 'mapped'
  */

  /**
   * Event listener callback
   * @callback module:ArApiInterface~ArApiInterface#AREventListenerCallback
   * @param {module:ArApiInterface~ArApiInterface#AREvent} event - AR event object depending on event type
   */

  /**
    * AR event type, see {@link module:ArApiInterface~ArApiInterface#EVENTTYPE} for values
    * @typedef {String} module:ArApiInterface~ArApiInterface#AREventType
    * @see {@link module:ArApiInterface~ArApiInterface#addEventListener addEventListener}
    */

  /**
   * @typedef {Object} module:ArApiInterface~ArApiInterface#ARAnchor
   * @property {THREE.Matrix4} transform the anchor's 4x4 transformation matrix
   * @property {Boolean} isTracked true if the transform is currently up to date
   *   * for ARKit this property will always be true for anchors other than image anchors
   * @property {String} uuid unique id of the anchor
   * @property {String} name optional name of the anchor
   * @property {Object} plane optional data for a plane anchor
   *   * for ARKit see {@link https://developer.apple.com/documentation/arkit/arplaneanchor plane anchor}
   * @property {String} plane.alignment alignment of plane, one of:
   *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arplaneanchor/alignment plane alignment types})
   *     * 'none'
   *     * 'horizontal'
   *     * 'vertical'
   *
   * @property {String} plane.classification optional classification of plane, one of:
   *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arplaneanchor/classification plane classification types})
   *     * 'none'
   *     * 'ceiling'
   *     * 'floor'
   *     * 'seat'
   *     * 'table'
   *     * 'wall'
   *     * 'window'
   *     * 'door'
   *
   * @property {THREE.Vector3} plane.center center point of the plane relative to its anchor position,
   *   * for ARKit see {@link https://developer.apple.com/documentation/arkit/arplaneanchor/2882056-center plane anchor center}
   *
   * @property {THREE.Vector3} plane.extent estimated width and length of the detected plane,
   *   * for ARKit see {@link https://developer.apple.com/documentation/arkit/arplaneanchor/2882055-extent plane anchor extent}
   *
   * @property {Object} image optional data for an image anchor
   *   * for ARKit see {@link https://developer.apple.com/documentation/arkit/arimageanchor image anchor}
   * @property {String} image.name optional name of the detected image
   * @property {Number} image.width physical width of the image (width that was provided alongside the image as additional information for tracking)
   * @property {Number} image.height physical height of the image (height that was provided alongside the image as additional information for tracking)
   */

  /**
   * @typedef {Object} module:ArApiInterface~ArApiInterface#ARHitTestResult
   * @property {Array<String>} type The kind of detected feature the search result represents. Array containing strings like one of the following:
   *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arhittestresult/resulttype hit test result types}):
   *     * 'featurePoint'
   *     * 'estimatedHorizontalPlane'
   *     * 'estimatedVerticalPlane'
   *     * 'existingPlane'
   *     * 'existingPlaneUsingExtent'
   *     * 'existingPlaneUsingGeometry'
   *
   * @property {ARAnchor} anchor The anchor representing the detected surface, if any.
   * @property {Number} distance The distance, in meters, from the camera to the detected surface.
   * @property {THREE.Matrix4} worldTransform The position and orientation of the hit test result relative to the world coordinate system.
   * @property {THREE.Matrix4} localTransform The position and orientation of the hit test result relative to the nearest anchor or feature point.
  */


  /**
   * Run a hit test, based on the latest available frame
   *
   * CAUTION: Be aware that the coordinates screenX and screenY are normalized screen coordinates.
   * The point (0,0) represents the top left corner of the screen, and the point (1,1) represents the bottom right corner.
   * As of now the AR view in the ShapeDiver App always occupies the complete screen, and the webview on top of it
   * occupies the complete screen as well. The coordinates need to be given with respect to the complete screen.
   *
   * For ARKit refer to these documentation pages:
   *   * {@link https://developer.apple.com/documentation/arkit/arframe ARFrame}
   *   * {@link https://developer.apple.com/documentation/arkit/arhittestresult ARHitTestResult}
   *   * {@link https://developer.apple.com/documentation/arkit/arframe/2875718-hittest ARFrame.hitTest}
   *
   * @param {Number} screenX Normalized screen X coordinate (considering the complete screen of the device).
   * @param {Number} screenY Normalized screen Y coordinate (considering the complete screen of the device).
   * @param {String|String[]} types Optional. The types of hit-test result to search for. String or array of strings according to the following list:
   *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arhittestresult/resulttype hit test result types}):
   *     * 'featurePoint'
   *     * 'estimatedHorizontalPlane'
   *     * 'estimatedVerticalPlane'
   *     * 'existingPlane'
   *     * 'existingPlaneUsingExtent'
   *     * 'existingPlaneUsingGeometry'
   *   * Defaults to the setting `ar.defaultHitTestType`, see {@link module:ApiInterfaceV2~ApiInterfaceV2#updateSettingAsync updateSettingAsync}
   *
   * @return {Promise} Promise which resolves to an array of {@link module:ArApiInterface~ArApiInterface#ARHitTestResult} on success (may be empty).
   */
  hitTest() {}

  /**
   * Register an anchor
   *
   * Anchors should be registered for virtual objects placed in the scene, this helps tracking.
   * When moving a virtual object, remove the existing anchor and register a new one.
   *
   * Register an event listener to ANCHOR_UPDATE to receives updates to the anchor's position.
   *
   * For ARKit refer to these documentation pages:
   *   * {@link https://developer.apple.com/documentation/arkit/aranchor ARAnchor}
   *   * {@link https://developer.apple.com/documentation/arkit/arsession/2865612-add ARSession.add}
   *
   * @param {THREE.Matrix4} transform Tranformation of new anchor
   * @param {String} name Optional descriptive name to use for the anchor, not supported for ARCore
   * @return {Promise} Promise which resolves to a unique identifier (String) of the newly added anchor.
   */
  registerAnchor() {}

  /**
   * Remove an anchor
   *
   * Use this function to remove an anchor previously added using registerAnchor
   *
   * For ARKit refer to these documentation pages:
   *   * {@link https://developer.apple.com/documentation/arkit/aranchor ARAnchor}
   *   * {@link https://developer.apple.com/documentation/arkit/arsession/2865607-remove ARSession.remove}
   *
   * @param {String} uuid Unique identifier of the anchor to remove
   * @return {Promise} Promise which resolves to a boolean indicating whether the anchor could be removed.
   */
  removeAnchor() {}

  /**
   * Pause the AR session
   *
   * While paused, the session doesn't track device motion or capture scene images using the device camera
   *
   * For ARKit refer to these documentation pages:
   *   * {@link https://developer.apple.com/documentation/arkit/arsession/2865619-pause ARSession.pause}
   *
   * @return {Promise} Promise which resolves once the session has been paused.
   */
  pauseSession() {}

  /**
   * Start running the AR session, continue running the AR session after it has been paused, reconfigure a running session, or reset tracking for a running session.
   *
   * Running the AR session does not automatically switch the default viewport to AR mode. The AR session can be run in the background,
   * without updating the default viewport based on tracking information. Synchronization of the camera has to be enabled separately, using
   * the setting `ar.enableCameraSync`.
   *
   * Call this function for a running AR session if you want it to be reconfigured or reset.
   *
   * For ARKit refer to these documentation pages:
   *   * {@link https://developer.apple.com/documentation/arkit/arsession/2875735-run ARSession.run}
   *
   * @param {String|String[]} planeDetection Optional. The types of planes that should be detected automatically. Defaults to no plane detection.
   *                                         Array containing strings according to the following list:
   *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arworldtrackingconfiguration world tracking configuration})
   *     * 'horizontal'
   *     * 'vertical'
   *
   * @param {String|String[]} options Optional. The options for running the session. No default options are set.
   *                                  Array containing strings according to the following list:
   *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arsession/runoptions run options})
   *     * 'removeExistingAnchors'
   *     * 'resetTracking'
   *
   * @return {Promise} Promise which resolves once the command has been processed by the app.
   */
  runSession() {}

  /**
   * Get status of AR interface
   *
   * Returns an object describing the status of the AR interface. This function should be used to detect whether AR functionality is available.
   *
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with data of type {@link module:ArApiInterface~ArApiInterface#ARStatusType}
   *         in case AR functionality, or data set to a falsy value if AR functionality is not available.
   */
  getStatus() {}

  /**
   * Add a callback to be invoked when a certain AR event occurs
   *
   * @param {module:ArApiInterface~ArApiInterface#AREventType} type - Type of AR event to subscribe to
   * @param {module:ArApiInterface~ArApiInterface#AREventListenerCallback} callback - Function to be called when the event fires
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API Response with an {@link module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken EventListenerToken} object.
   *  The event listener token can be used to remove the event listener by calling {@link module:ArApiInterface~ArApiInterface#removeEventListener removeEventListener}.
   */
  addEventListener() {}

  /**
   * Remove an event listener for an AR event type
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken} token - event listener token for event listener to be removed
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API Response with a Boolean data, indicating whether the event listener could be removed.
   */
  removeEventListener() {}

  /**
   * Get id of anchor currently used for object placement.
   * Use this to check whether object has been placed yet.
   *
   * @param {String} runtimeId Runtime id of the plugin whose currently used placement anchor shall be returned (ignored for now)
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API Response containing id of currently used placement anchor, empty string in case no anchor is set
   */
  getPlacementAnchor() {}

  /**
   * Reset anchor currently used for object placement.
   *
   * This results in the object going back to placement mode.
   *
   * @param {String} runtimeId Runtime id of the plugin whose currently used placement anchor shall be reset (ignored for now)
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API Response containing id of previously used placement anchor, empty string in case no anchor was set
   */
  resetPlacementAnchor() {}

  /**
   * Set id of anchor to be used for object placement.
   *
   * @param {String} anchorId Id of anchor to be used for object placement.
   * @param {String} runtimeId Runtime id of the plugin whose placement anchor shall be set (ignored for now)
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API Response containing id of previously used placement anchor, empty string in case no anchor was set
   */
  setPlacementAnchor() {}

}

/**
 * Enum for supported AR event types.
 * @readonly
 * @enum {module:ArApiInterface~ArApiInterface#AREventType}
 */
ArApiInterface.prototype.EVENTTYPE = {
  /** a new image anchor has been created, updates to its position will be part of ANCHOR_UPDATE events */
  ANCHOR_CREATE_IMAGE : 'anchor.create.image',
  /** anchor positions have been updated */
  ANCHOR_UPDATE : 'anchor.update',
  /** placement anchor of object has changed */
  OBJECT_PLACEMENT : 'object.placement',
  /** the status of tracking of the device (camera) has changed */
  STATUS_TRACKING : 'status.tracking',
  /** the status of mapping of the virtual world has changed */
  STATUS_MAPPING : 'status.mapping',
  /** the webview contained in the native AR app did appear */
  STATUS_WEBVIEW_DIDAPPEAR : 'status.webview.didappear',
  /** the webview contained in the native AR app will disappear */
  STATUS_WEBVIEW_WILLDISAPPEAR : 'status.webview.willdisappear',
};

module.exports = ArApiInterface;

/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiParameterInterfaceV2.1.js*
 *
 * ### Content
 *   * Abstract definition of the ShapeDiver 3D Viewer Parameter API V2.1
 *
 * @module ApiParameterInterface
 * @author ShapeDiver <contact@shapediver.com>
 */

////////////
////////////
//
// Parameter Interface
//
////////////
////////////

/**
  * Export event type, see {@link module:ApiParameterInterface~ApiParameterInterface#EVENTTYPE} for values
  * @typedef {String} module:ApiParameterInterface~ApiParameterInterface#ParameterEventType
  */

/**
 * Parameter update result, see {@link module:ApiParameterInterface~ApiParameterInterface#RESULT} for values
 *
 * @typedef {String} module:ApiParameterInterface~ApiParameterInterface#ParameterUpdateResult
 */

/**
 * Parameter update object, provides the possibility to select parameters by their id or name
 * @typedef {Object} module:ApiParameterInterface~ApiParameterInterface#ParameterUpdateObject
 * @property {String} [id] - id of parameter, takes precedence over idOrName and name
 * @property {String} [idOrName] - id or name of parameter, takes precedence over name
 * @property {String} [name] - name of parameter, last priority after id and idOrName
 * @property {String} [plugin] - optional runtime id of plugin the parameter belongs to, specify this when using several sessions to the same ShapeDiver model
 * @property {*} value - Parameter value to set according to parameter {@link module:ApiParameterInterface~ApiParameterInterface#TYPE}
 * @property {module:ApiParameterInterface~ApiParameterInterface#ParameterUpdateResult} [result] - Result of the parameter update, will be set in response to update
 */

/**
* Parameter type, see {@link module:ApiParameterInterface~ApiParameterInterface#TYPE} for values
* @typedef {String} module:ApiParameterInterface~ApiParameterInterface#ParameterType
*/

/**
 * Parameter visualization, see {@link module:ApiParameterInterface~ApiParameterInterface#VISUALIZATION} for values
 * @typedef {String} module:ApiParameterInterface~ApiParameterInterface#ParameterVisualization
 */

/**
* Parameter definition
* @typedef {Object} module:ApiParameterInterface~ApiParameterInterface#ParameterDefinition
* @property {String} id - unique id of the parameter (unique within a plugin instance)
* @property {String} [name] - name of the parameter
* @property {String} [_name] - original name of the parameter, will be set in case the parameter name got changed from its original value
* @property {String} [note] - description of the parameter
* @property {module:ApiParameterInterface~ApiParameterInterface#ParameterType} [type] - type of the parameter
* @property {Number} [decimalplaces] - number of decimal places for type Float
* @property {String[]} [choices] - choices for type StringList
* @property {Number|String|Boolean} defval - default value, represented as string
* @property {Number|String|Boolean} value - current value of the parameter
* @property {String} [group] - group of the parameter for visualization purposes
* @property {String} [max] - maximum value for numeric types, max length for type String, max size for type File/Blob
* @property {String} [min] - minimum value for numeric types
* @property {String} [plugin] - optional runtime id of plugin the parameter belongs to
* @property {Boolean} [hidden] - must exist and be true if the parameter should be hidden from the ui
* @property {Number} [order] - optional number used for ordering parameters in the ui
* @property {module:ApiParameterInterface~ApiParameterInterface#ParameterVisualization} [visualization] - how to visualize the parameter
*/

/**
* ### ShapeDiver Viewer - Parameter API
*
* The parameters interface is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2 API}.
*
* It allows to
*  * get the definition of currently registered parameters, and
*  * update the values of currently registered parameters, which in turns results in an update of the scene.
*
* @interface ApiParameterInterface
*/
var ApiParameterInterface = function () {

  /**
   * Enum for supported parameter event types.
   * @readonly
   * @enum {module:ApiParameterInterface~ApiParameterInterface#ParameterEventType}
   */
  this.EVENTTYPE = {
    /** a parameter has been registered */
    REGISTERED: 'registered',
    /** the definition of a parameter has been updated, typically one of the properties name or order or hidden */
    UPDATE: 'update',
    /** the value of a parameter has been changed */
    VALUE_UPDATE: 'value.update',
  };

  /**
  * Enum for parameter update result.
  * @readonly
  * @enum {module:ApiParameterInterface~ApiParameterInterface#ParameterUpdateResult}
  */
  this.RESULT = {
    /** the parameter was not found */
    PARAM_NOT_FOUND: 'param_not_found',
    /** no value was given */
    NO_VALUE: 'no_value',
    /** the new parameter value was rejected */
    VALUE_REJECT: 'value_reject',
    /** the parameter value had already been set */
    VALUE_EXISTS: 'value_exists',
    /** the new parameter value was accepted */
    VALUE_OK: 'value_ok',
    /** the plugin responsible to handle the parameter update was not found */
    PLUGIN_NOT_FOUND: 'plugin_not_found',
    /** the parameter update failed */
    ERROR: 'error',
    /** the parameter update results in a live update */
    LIVE: 'live',
    /** the parameter update results in an update which can be answered from the local cache */
    CACHE: 'cache',
    /** the parameter update results in an update which needs to be loaded from somewhere */
    LOAD: 'load',
    /** the parameter update was aborted, it was superseded by another update */
    ABORT: 'abort'
  };

  /**
  * Enum for parameter type.
  * @readonly
  * @enum {module:ApiParameterInterface~ApiParameterInterface#ParameterType}
  */
  this.TYPE = {
    /** floating point number */
    FLOAT: 'Float',
    /** integer number */
    INT: 'Int',
    /** even integer number */
    EVEN: 'Even',
    /** odd integer number */
    ODD: 'Odd',
    /** string with maximum length */
    STRING: 'String',
    /** color string, including opacity (10 digits, e.g. 0xffffffaa) */
    COLOR: 'Color',
    /** choose from a list of strings */
    STRINGLIST: 'StringList',
    /** boolean */
    BOOL: 'Bool',
    /** date / time (currently unsupported) */
    TIME: 'Time',
    /** JavaScript {@link https://developer.mozilla.org/en-US/docs/Web/API/File File} or {@link https://developer.mozilla.org/en-US/docs/Web/API/Blob Blob} */
    FILE: 'File'
  };

  /**
  * Enum for parameter visualization.
  * @readonly
  * @enum {module:ApiParameterInterface~ApiParameterInterface#ParameterVisualization}
  */
  this.VISUALIZATION = {
    /** currently the only choice for numeric types (Float, Int, Even, Odd) */
    SLIDER: 'slider',
    /** for type StringList */
    SEQUENCE: 'sequence',
    /** for type StringList */
    CYCLE: 'cycle',
    /** for type StringList */
    DROPDOWN: 'dropdown',
    /** for type StringList */
    CHECKLIST: 'checklist',
    /** for type Time */
    CLOCK: 'clock',
    /** for type Time */
    CALENDAR: 'calendar'
  };

  /**
  * Get parameter definitions for some or all parameters
  *
  * @param {module:ApiParameterInterface~ApiParameterInterface#ParameterDefinition} [filter] - Definitions for parameters whose definition matches the properties of this filter object will be returned, may be empty in which case all parameter definitions will be returned
  * @param {String} [filter.id] - id of parameter
  * @param {String} [filter.name] - name of parameter
  * @param {String} [filter.plugin] - runtime id of plugin the parameter belongs to
  * @param {String} [filter.type] - type of parameter
  * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an array of {@link module:ApiParameterInterface~ApiParameterInterface#ParameterDefinition parameter definitions}
  */
  this.get = function () { };

  /**
   * Update parameter values and corresponding parts of the scene.
   *
   * Depending on which parameters are included in the request, the update may involve several plugins.
   * The return value will include a status code for each parameter which was specified in the request.
   *
   * @param  {module:ApiParameterInterface~ApiParameterInterface#ParameterUpdateObject[]} values - Objects defining the parameters to update and their new values
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a data array of {@link ParameterUpdateObject ParameterUpdateObject} objects, each including the result property, for all parameters which had been provided. In case the scene update fails or is aborted due to a newer update, the APIResponse will not contain a data array but an err.
   */
  this.updateAsync = function () { };

  /**
   * Check whether it is possible to go back in history
   * @see {@link module:ApiParameterInterface~ApiParameterInterface#canGoForwardInHistory canGoForwardInHistory}
   * @see {@link module:ApiParameterInterface~ApiParameterInterface#goBackInHistoryAsync goBackInHistoryAsync}
   * @see {@link module:ApiParameterInterface~ApiParameterInterface#goForwardInHistoryAsync goForwardInHistoryAsync}
   * @return {Boolean} true if it is possible to go back in history
   */
  this.canGoBackInHistory = function () { };

  /**
   * Check whether it is possible to go forward in history
   * @see {@link module:ApiParameterInterface~ApiParameterInterface#canGoBackInHistory canGoBackInHistory}
   * @see {@link module:ApiParameterInterface~ApiParameterInterface#goBackInHistoryAsync goBackInHistoryAsync}
   * @see {@link module:ApiParameterInterface~ApiParameterInterface#goForwardInHistoryAsync goForwardInHistoryAsync}
   * @return {Boolean} true if it is possible to go forward in history
   */
  this.canGoForwardInHistory = function () { };

  /**
   * Go back in history, if this is possible
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @see {@link module:ApiParameterInterface~ApiParameterInterface#canGoBackInHistory canGoBackInHistory}
   * @see {@link module:ApiParameterInterface~ApiParameterInterface#canGoForwardInHistory canGoForwardInHistory}
   * @see {@link module:ApiParameterInterface~ApiParameterInterface#goForwardInHistoryAsync goForwardInHistoryAsync}
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a data array of {@link ParameterUpdateObject ParameterUpdateObject} objects, corresponding to the parameter state after the movement in history. In case the scene update fails or is aborted due to a newer update, the APIResponse will not contain a data array but an err.
   */
  this.goBackInHistoryAsync = function () { };

  /**
   * Go forward in history, if this is possible
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @see {@link module:ApiParameterInterface~ApiParameterInterface#canGoBackInHistory canGoBackInHistory}
   * @see {@link module:ApiParameterInterface~ApiParameterInterface#canGoForwardInHistory canGoForwardInHistory}
   * @see {@link module:ApiParameterInterface~ApiParameterInterface#goBackInHistoryAsync goBackInHistoryAsync}
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a data array of {@link ParameterUpdateObject ParameterUpdateObject} objects, corresponding to the parameter state after the movement in history. In case the scene update fails or is aborted due to a newer update, the APIResponse will not contain a data array but an err.
   */
  this.goForwardInHistoryAsync = function () { };

  /**
  * Update properties of registered parameters.
  *
  * The following properties can be updated: name, order, hidden
  * This function will fail in case no unique parameter can be found for each parameter definition given.
  *
  * @param {module:ApiParameterInterface~ApiParameterInterface#ParameterDefinition[]} [definitions] - updated parameter definitions
  * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with its data set to a boolean according to success or error
  */
  this.updateProperties = function () { };

  /**
   * Add a callback to be invoked when a certain event occurs
   *
   * @param {module:ApiParameterInterface~ApiParameterInterface#ParameterEventType} type - Type of event to subscribe to
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback} callback - Function to be called when the event fires
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an {@link module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken EventListenerToken} object. The event listener token can be used to remove the event listener by calling {@link module:ApiParameterInterface~ApiParameterInterface#removeEventListener removeEventListener}.
   */
  this.addEventListener = function () { };

  /**
   * Remove an event listener
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken} token - event listener token for event listener to be removed
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the event listener could be removed.
   */
  this.removeEventListener = function () { };

};

module.exports = ApiParameterInterface;
/**
 * @file The default renderingHandler. Handlers all functionality related to rendering.
 *
 * @module RenderingHandlerDefault
 * @author Michael Oppitz
 */

let RenderingHandler = function (___settings, ___handlers) {
  const THREE = require('../../../externals/three'),
        TWEEN = require('@tweenjs/tween.js'),
        GLOBAL_UTILS = require('../../../shared/util/GlobalUtils'),
        TO_TINY_COLOR = require('../../../shared/util/toTinyColor'),
        RenderingHandlerInterface = require('../../interfaces/handlers/RenderingHandlerInterface'),
        RENDER_MODES = {
          NONE: -1,
          STANDARD: 0,
          BEAUTY: 1,
          BLENDING: 2,
        },
        BEAUTY_RENDER_BLENDING_ID = 'beautyRenderBlending',
        BUSY_MODE_ID = 'busyMode',
        UNREGISTERED_RESIZE_EVENT_ID = 'unregisteredResizeEvent',
        REGISTERED_RESIZE_EVENT_ID = 'registeredResizeEvent',
        CAMERA_MOVING_ID = 'cameraMoving',
        MESSAGE_PROTOTYPE = require('../../../shared/messages/MessagePrototype'),
        MESSAGING_CONSTANTS = require('../../../shared/constants/MessagingConstants'),
        StateDashboardLibrary = require('../../../modules/shapediver-state-dashboard/StateDashboard').StateDashboardLibrary,
        _settings = ___settings.settings,
        _scene = ___settings.scene,
        _geometryNode = ___settings.geometryNode,
        _container = ___settings.container,
        _pathUtils = ___settings.pathUtils,
        // Processes can register with a unique ID to render continously.
        _continuousRenderingList = [],
        _handlers = ___handlers;

  let that,
      _dashboard,
      _width = _container.offsetWidth,
      _height = _container.offsetHeight,
      _screenSizeFullscreen = false,
      // As a first mode the standard mode is chosen
      _renderMode = RENDER_MODES.NONE,
      _beautyRenderDelayTimeout = null,
      _helpers, _renderer,
      _updateShadowMap = false,
      _keepUpdatingShadowMap = false,
      _resizeTimer = -1,
      _blurPromise = null,
      _alpha = null,
      _rendering = true,
      _started = false,
      _startBeautyRendering = false,
      _lastFrame = Date.now(),
      _framerateMeasurement = 0,
      _framerateCounter = 0;

  ////////////
  ////////////
  //
  // the hooks for the settings go below
  //
  ////////////
  ////////////

  /**
   * A notifier that is called to render after a setting has been updated.
   *
   * @param {String} name The name of the setting
   * @param {*} oldVal The old value of the setting
   * @param {*} newVal The new value of the setting
   */
  let _renderNotifier = function (name, oldVal, newVal) {
    if (oldVal !== newVal)
      that.render();
  };

  /**
   * Toggles the shadows.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _shadowsHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', _handlers.threeDManager.warn, 'RenderingHandler.Hook->shadows')) return false;
    _handlers.lightHandler.setToggleLightShadows(toggle);
    return true;
  };

  /**
   * Toggles the ambient occlusion.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _ambientOcclusionHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', _handlers.threeDManager.warn, 'RenderingHandler.Hook->ambientOcclusion')) return false;
    return true;
  };

  /**
   * Sets the clear color renderer.
   *
   * @param {HexaDecimalNumber} color The new color as hexadecimal (e.g. 0xffffff)
   * @returns {Boolean} If the mode was successfully set
   */
  let _clearColorHook = function (color) {
    if (!GLOBAL_UTILS.typeCheck(color, 'color', _handlers.threeDManager.warn, 'RenderingHandler.Hook->clearColor')) return false;

    let tc = TO_TINY_COLOR(color);
    if (_alpha !== null)
      _renderer.setClearColor(tc.toThreeColor(), _alpha);
    else
      _renderer.setClearColor(tc.toThreeColor());
    that.render();
    return true;
  };

  /**
   * Sets the clear alpha renderer.
   *
   * @param {Number} alpha The new alpha
   * @returns {Boolean} If the mode was successfully set
   */
  let _clearAlphaHook = function (alpha) {
    if (!GLOBAL_UTILS.typeCheck(alpha, 'notnegative', _handlers.threeDManager.warn, 'RenderingHandler.Hook->clearAlpha')) return false;

    _alpha = alpha;
    _renderer.setClearAlpha(alpha);
    that.render();
    return true;
  };

  /**
   * Sets the size of point objects.
   *
   * @param {Number} size The new size
   * @returns {Boolean} If the mode was successfully set
   */
  let _pointSizeHook = function (size) {
    if (!GLOBAL_UTILS.typeCheck(size, 'notnegative', _handlers.threeDManager.warn, 'RenderingHandler.Hook->pointSize')) return false;
    return true;
  };

  /**
   * Renders after the point size was set
   */
  let _pointSizeNotifier = function () {
    that.render();
  };

  /**
   * @extends module:RenderingHandlerInterface~RenderingHandlerInterface
   * @lends module:RenderingHandlerDefault~RenderingHandler
   */
  class RenderingHandler extends RenderingHandlerInterface {

    /**
     * Constructor of the Rendering Handler
     *
     * @param {Object} ___settings - Instantiation settings
     * @param {Object} ___settings.settings - The default settings object
     * @param {THREE.Scene} ___settings.scene - The 3D scene
     * @param {HTMLElement} ___settings.container - The container that the renderer should be in
     */
    constructor() {
      super();

      that = this;

      _dashboard = StateDashboardLibrary.getInstance(_handlers.threeDManager.viewerApi.getSetting('viewerRuntimeId'));

      _helpers = new (require('../helpers/handlers/RenderingHandlerHelpers'))({
        handlers: _handlers,
        geometryNode: _geometryNode,
        pathUtils: _pathUtils
      });

      let response = _helpers.createWebGLRenderer(_container, {
        antialias: true,
        alpha: true,
        preserveDrawingBuffer: true,
      });
      _handlers.threeDManager.success = response.success;

      // handle response (logging and messaging)
      _helpers.handleCreateWebGLRendererResponse('RenderingHandler', response, 'WebGL context was created', 'WebGL context could not be created');
      if (response.success === false) {
        return null;
      }

      _renderer = response.renderer;
      _renderer.setPixelRatio(window.devicePixelRatio);
      _renderer.setSize(_width, _height, true /* false: do not update css style of canvas */);
      _renderer.setClearColor(TO_TINY_COLOR(_settings.getSetting('clearColor'), 'white').toThreeColor(), _settings.getSetting('clearAlpha'));
      _renderer.shadowMap.enabled = true;
      // With the autoUpdate disabled, the function updateShadowMap has to be called to update the shadow map
      _renderer.shadowMap.autoUpdate = false;
      _renderer.shadowMap.needsUpdate = true;
      _renderer.shadowMap.type = THREE.PCFShadowMap;
      _renderer.gammaFactor = 1.0;
      _renderer.gammaInput = false;
      _renderer.gammaOutput = true;
      _renderer.textureUnitCount = response.renderer.context.getParameter(response.renderer.context.MAX_TEXTURE_IMAGE_UNITS);
      if (!_renderer.textureUnitCount) _renderer.textureUnitCount = 8;
      _renderer.depth = false;
      /**
      * Perform continuous render calls if the window size changes
      * Unfortunately it is not possible currently to be notified about resizing of individual DOM elements,
      * see https://developer.mozilla.org/en-US/docs/Web/Events/resize
      */
      window.onresize = function () {
        if (_resizeTimer === -1)
          that.registerForContinuousRendering(REGISTERED_RESIZE_EVENT_ID);
        clearTimeout(_resizeTimer);
        _resizeTimer = setTimeout(function () {
          _resizeTimer = -1;
          that.unregisterForContinuousRendering(REGISTERED_RESIZE_EVENT_ID);
          that.render();
        }, 250);
      };

      ////////////
      ////////////
      //
      // Beauty Render Handler
      //
      ////////////
      ////////////

      _handlers.beautyRenderHandler = new (require('./BeautyRenderHandler'))({
        settings: _settings,
        scene: _scene,
        geometryNode: _geometryNode,
        renderer: _renderer
      }, _handlers);
      _handlers.threeDManager.beautyRenderHandler = _handlers.beautyRenderHandler;

      _handlers.threeDManager.viewerApi.state.addEventListener(_handlers.threeDManager.viewerApi.state.EVENTTYPE.BUSY, function() {
        if(_continuousRenderingList.indexOf(BUSY_MODE_ID) === -1)
          that.registerForContinuousRendering(BUSY_MODE_ID);
      });

      _handlers.threeDManager.viewerApi.state.addEventListener(_handlers.threeDManager.viewerApi.state.EVENTTYPE.IDLE, function() {
        that.unregisterForContinuousRendering(BUSY_MODE_ID);
      });

      _settings.registerNotifier('shadows', _renderNotifier);
      _settings.registerNotifier('ambientOcclusion', _renderNotifier);
      _settings.registerNotifier('pointSize', _pointSizeNotifier);
      _settings.registerHook('shadows', _shadowsHook);
      _settings.registerHook('ambientOcclusion', _ambientOcclusionHook);
      _settings.registerHook('clearColor', _clearColorHook);
      _settings.registerHook('clearAlpha', _clearAlphaHook);
      _settings.registerHook('pointSize', _pointSizeHook);
    }

    /**
     * Registers a resize event in the _continuousRenderingList and unregisterst 100 ms later.
     */
    _resizeEvent() {
      that.registerForContinuousRendering(UNREGISTERED_RESIZE_EVENT_ID, false);
      setTimeout(function () { that.unregisterForContinuousRendering(UNREGISTERED_RESIZE_EVENT_ID); }, 100);
    }

    /**
     * This function is always called before rendering.
     * It clears the renderer, updates the uniforms and updates the shadow map, if requested.
     *
     * IMPORTANT: Has to be called before every render call!
     */
    _beforeRender(time) {
      _renderer.clear();

      TWEEN.update(time);
      _handlers.cameraHandler.updateCameraControls(time-_lastFrame);

      let width, height, screenSizeFullscreen;

      // Determine if in fullscreen and get current size
      if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.mozFullScreenElement) {

        screenSizeFullscreen = true;
        let element = _renderer.domElement;
        if (element.getAttribute('sdv-fullscreen') == 'true' || _renderer.domElement.parentNode == null) {
          width = screen.width;
          height = screen.height;
        }
        else {
          width = _renderer.domElement.parentNode.offsetWidth;
          height = _renderer.domElement.parentNode.offsetHeight;
        }
      } else {
        screenSizeFullscreen = false;
        width = _renderer.domElement.parentNode.offsetWidth;
        height = _renderer.domElement.parentNode.offsetHeight;
      }

      // If the size is different, update everything
      if (width !== _width || height !== _height || _screenSizeFullscreen !== screenSizeFullscreen) {
        that._resizeEvent();
        _width = width;
        _height = height;
        _screenSizeFullscreen = screenSizeFullscreen;
        _renderer.setSize(_width, _height, true /* false: do not update css style of canvas */);
        _handlers.cameraHandler.onResize(_width, _height);
        _handlers.beautyRenderHandler.setSize(_width, _height);
      }

      _handlers.beautyRenderHandler.updateCustomUniforms();

      if (_updateShadowMap === true || _keepUpdatingShadowMap === true) {
        _updateShadowMap = false;
        _renderer.shadowMap.needsUpdate = true;
      }
    }

    /**
     * Returns the extension with the given name, if available.
     * @param {String} name The name of the extension
     * @returns {Object} The extension with the specified name, if available
     */
    _getExtension(name) {
      return _renderer.extensions.get(name);
    }

    _getTextureUnitCount() {
      return _renderer.textureUnitCount;
    }

    _framerateMeasurement(renderMode, delta) {
      if(renderMode === RENDER_MODES.STANDARD) {
        _framerateMeasurement += delta;
        _framerateCounter++;
        if(_framerateMeasurement > 1000) {
          _framerateCounter -= (_framerateMeasurement - 1000) / delta;
          let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.GENERIC, {
            type: MESSAGING_CONSTANTS.messageTopics.SCENE_FRAMERATE,
            viewportRuntimeId: _handlers.threeDManager.runtimeId,
            framerate: _framerateCounter
          });
          _handlers.threeDManager.message(MESSAGING_CONSTANTS.messageTopics.SCENE_FRAMERATE, m);
          _framerateMeasurement = 0;
          _framerateCounter = 0;
        }
      } else {
        _framerateMeasurement = 0;
        _framerateCounter = 0;
      }
    }

    ////////////
    ////////////
    //
    // RenderingHandler API
    //
    ////////////
    ////////////

    render(time) {
      let timeDefined = time !== undefined;
      time = performance.now();

      /**
       * evaluate reasons on why not to render
       */

      // if the state of firstTimeVisible is not resolve, return
      if(!_dashboard.firstTimeVisible.resolved) return;
      // the rendering handler was not started properly yet
      if(_started === false) return;
      // The viewport was just destroyed, this is the last render call
      if (!_handlers.threeDManager || !_renderer.domElement) return;
      // Don't render when the viewport should not be shown
      if (_handlers.threeDManager.getSetting('show') === false) return;
      // if the rendering was disabled manually
      if (!_rendering) return;
      // double render call
      if (time-_lastFrame === 0) return;

      // in case a timeout for starting beauting rendering is currently set, skip it
      if (_beautyRenderDelayTimeout) {
        clearTimeout(_beautyRenderDelayTimeout);
        _beautyRenderDelayTimeout = null;
      }

      // if this is a call that was not made by requestAnimationFrame
      if(!timeDefined) {

        // disrupt blending
        if (_continuousRenderingList.indexOf(BEAUTY_RENDER_BLENDING_ID) !== -1) {
          that.unregisterForContinuousRendering(BEAUTY_RENDER_BLENDING_ID);
          // send message to notify about cancelled beauty rendering
          let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.GENERIC/*, data, token*/);
          _handlers.threeDManager.message(MESSAGING_CONSTANTS.messageTopics.SCENE_RENDER_BEAUTY_CANCEL, m);
          return;
        }

        // if rendering is going on anyway, we skip this additional render call
        if ( _renderMode === RENDER_MODES.NONE ) 
          requestAnimationFrame(that.render);
        return;
      }

      if(_renderMode === RENDER_MODES.NONE) _lastFrame = time;

      /**
       * measure the frame rate
       */
      that._framerateMeasurement(_renderMode, time-_lastFrame);

      /**
       * select render mode
       */
      if (_continuousRenderingList.length === 1 && _continuousRenderingList.indexOf(BEAUTY_RENDER_BLENDING_ID) !== -1) {
        // continue blending
        _renderMode = RENDER_MODES.BLENDING;
      } else if (_continuousRenderingList.length === 0 && _startBeautyRendering === true) {
        // start beauty rendering
        _renderMode = RENDER_MODES.BEAUTY;
      } else {
        // render normally
        _renderMode = RENDER_MODES.STANDARD;
      }
      _startBeautyRendering = false;

      /**
       * quit blending if it was interrupted
       */
      if (_renderMode === RENDER_MODES.STANDARD && _continuousRenderingList.indexOf(BEAUTY_RENDER_BLENDING_ID) !== -1) {
        that.unregisterForContinuousRendering(BEAUTY_RENDER_BLENDING_ID);
        // send message to notify about cancelled beauty rendering
        let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.GENERIC/*, data, token*/);
        _handlers.threeDManager.message(MESSAGING_CONSTANTS.messageTopics.SCENE_RENDER_BEAUTY_CANCEL, m);
      }

      that._beforeRender(time);
      _handlers.threeDManager.helpers.toggleViewport(true, time);

      if (_renderMode == RENDER_MODES.STANDARD) {
        _handlers.beautyRenderHandler.renderStandard();
        // it's sufficient to process anchors in standard rendering mode, the camera does not move while beauty rendering
      } else if (_renderMode == RENDER_MODES.BLENDING) {
        // the beauty render handler will unregister from continuous rendering once it is done with blending (BEAUTY_RENDER_BLENDING_ID)
        _handlers.beautyRenderHandler.renderBlending();
      } else if (_renderMode == RENDER_MODES.BEAUTY) {
        // the beauty render handler will register for continuous rendering if this is necessary (BEAUTY_RENDER_BLENDING_ID)
        _handlers.beautyRenderHandler.renderBeauty();
      }

      _helpers.processAnchors(_renderer, _scene, _handlers.cameraHandler.camera, _handlers.threeDManager.helpers.getAnchors());
      _handlers.threeDManager.helpers.toggleViewport(false, time);

      /**
       * request a new animation frame
       *   according to above logic and the behavior of BeautyRenderHandler, this is
       *   only necessary if some sort of continuous rendering is active
       */
      if (_continuousRenderingList.length > 0) {

        requestAnimationFrame(that.render);

      } else {

        /**
         * No more continuous rendering going on, check if we should
         * set a delay for starting beauty rendering
         * beauty rendering should only be started if
         * - we are in standard rendering mode, AND
         * - no continuous rendering is going on, AND
         * - it makes sense to use beauty rendering
         */
        if (_renderMode === RENDER_MODES.STANDARD && _handlers.beautyRenderHandler.beautyRenderingActive()) {
          _beautyRenderDelayTimeout = setTimeout(function () {
            _startBeautyRendering = true;
            _beautyRenderDelayTimeout = null;
            requestAnimationFrame(that.render);
          }, _settings.getSetting('beautyRenderDelay'));
        }

        _renderMode = RENDER_MODES.NONE;
      }

      _lastFrame = time;
    }

    /** @inheritdoc */
    renderHidden() {
      _handlers.beautyRenderHandler.renderHidden();
    }

    /** @inheritdoc */
    onResize(width, height) {
      _renderer.setSize(width, height);
    }

    /** @inheritdoc */
    getSize() {
      return _renderer.getSize();
    }



    
    /** @inheritdoc */
    start() {
      _started = true;
    }

    /** @inheritdoc */
    pause() {
      _rendering = false;
    }

    /** @inheritdoc */
    resume() {
      _rendering = true;
      _renderMode = RENDER_MODES.NONE;
    }

    /** @inheritdoc */
    destroy() {
      _renderer.dispose();
      try {
        _renderer.forceContextLoss();
      } catch (e) {
        // this fails only if there is an extension mission
        // in that case we can't do much
      }
    }




    /** @inheritdoc */
    getScreenshot() {
      // Comment by Alex: options could be passed on here
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toDataURL
      // optionally we should support canvas.toBlob (see https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob)
      // maybe implement a separate function for this
      return _renderer.domElement.toDataURL('image/png');
    }

    /** @inheritdoc */
    getDomElement() {
      return _renderer.domElement;
    }

    /** @inheritdoc */
    getCanvas() {
      return _renderer.context.canvas;
    }

    /** @inheritdoc */
    getRendererInfo() {
      return _renderer.info;
    }

    /** @inheritdoc */
    setBlur(blur, options) {
      if (options == null || !options.hasOwnProperty('duration')) {
        let p = _blurPromise || Promise.resolve();
        _blurPromise = p.then(function () {
          _renderer.domElement.style.filter = blur ? 'blur(3px)' : '';
        });
        return;
      }

      _blurPromise = _blurPromise || Promise.resolve();
      _blurPromise = _blurPromise.then(function () {
        let blurProperties = { r: blur ? 0.0 : 3.0 };
        return new Promise(function (resolve) {
          let tweenBlur = new TWEEN.Tween(blurProperties).to({
            r: blur ? 3.0 : 1.0
          }, options.duration).onUpdate(function () {
            _renderer.domElement.style.filter = 'blur(' + blurProperties.r + 'px)';
          }).onComplete(function () {
            _renderer.domElement.style.filter = blur ? 'blur(3px)' : '';
            resolve();
          });
          tweenBlur.start();
        });
      });
    }

    /** @inheritdoc */
    registerForContinuousRendering(id, rendering) {
      if (id === undefined)
        return;

      // exception for the camera id, which may only be added once
      // FIXME Alex questions why we allow other ids to be added several times?
      if (!_continuousRenderingList.includes(CAMERA_MOVING_ID) || id !== CAMERA_MOVING_ID)
        _continuousRenderingList.push(id);

      if(rendering !== false)
        that.render();
    }

    /** @inheritdoc */
    unregisterForContinuousRendering(id) {
      let index = _continuousRenderingList.indexOf(id);
      if (index > -1)
        _continuousRenderingList.splice(index, 1);
    }

    /** @inheritdoc */
    containsContinuousRendering(id) {
      return _continuousRenderingList.indexOf(id) === -1 ? false : true;
    }




    /** @inheritdoc */
    updateShadowMap() {
      _updateShadowMap = true;
    }

    /** @inheritdoc */
    keepUpdatingShadowMap() {
      _keepUpdatingShadowMap = true;
    }

    /** @inheritdoc */
    stopUpdatingShadowMap() {
      _keepUpdatingShadowMap = false;
    }

    getRenderer() {
      return _renderer;
    }
  }

  return new RenderingHandler(___settings);
};

module.exports = RenderingHandler;
